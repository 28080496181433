import { z } from 'zod'
import { isLongEnough, hasNumber, hasLetter } from '../utils/passwordValidator'

type ValidateStatus = 'optional' | 'required'
type ReturnCondition<
	TStatus,
	TField extends z.ZodTypeAny
> = TStatus extends 'required' ? TField : z.ZodOptional<TField>

const emptyStringToUndefined = z.literal('').transform(() => undefined)

function getOptionalText(schema: z.ZodTypeAny, isRequired?: boolean) {
	if (isRequired) {
		return schema
	}

	return schema.optional().or(emptyStringToUndefined)
}

function getOptionalCheckbox(errorText: string, isRequired?: boolean) {
	if (isRequired) {
		return z.literal(true, {
			errorMap: () => ({
				message: errorText,
			}),
		})
	}

	return z.boolean().optional()
}

// TODO - remove commented out parts if image, cv & documents are removed
// https://trenkwalder.atlassian.net/browse/WEBP-3253
function getOptionalFile(
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	errorText: string,
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	isRequired?: boolean,
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	isMultiple?: boolean
) {
	// if (isRequired) {
	// 	return z.any().refine((files) => {
	// 		return files?.length === 1
	// 	}, errorText)
	// }

	return z.any()
	// return z.any().array().or(emptyStringToUndefined)
}

const PasswordValidator = (param: string) => {
	return isLongEnough(param) && hasNumber(param) && hasLetter(param)
}

export const useSchema = (t: (value: string) => string) => {
	const requiredError = t('Required')
	const phoneError = t('Please enter correct phone number')
	const emailError = t('Please enter your email address')
	const passwordError = t('Password must contain at least 8 characters')
	const tooLongError = t('Too long')
	const notMatch = t('Passwords do not match')

	function text<T extends ValidateStatus>(
		state: T
	): ReturnCondition<T, z.ZodString> {
		const schema = z
			.string({
				required_error: t('Required') as string,
			})
			.trim()
			.min(1, requiredError)

		return getOptionalText(schema, state === 'required') as any
	}

	function description<T extends ValidateStatus>(
		state: T
	): ReturnCondition<T, z.ZodString> {
		const schema = z.string().trim().min(1, requiredError)

		return getOptionalText(schema, state === 'required') as any
	}

	function number<T extends ValidateStatus>(
		state: T,
		maxLength?: string
	): ReturnCondition<T, z.ZodNumber> {
		const schema = z
			.string({
				required_error: t('Required') as string,
			})
			.min(1, requiredError)
			.transform((value, ctx) => {
				if (maxLength && value.length > Number(maxLength)) {
					ctx.addIssue({
						code: z.ZodIssueCode.custom,
						message: tooLongError,
					})
					return z.NEVER
				}
				return Number(value)
			})

		return getOptionalText(schema, state === 'required') as any
	}

	function email<T extends ValidateStatus>(
		state: T
	): ReturnCondition<T, z.ZodString> {
		const schema = z
			.string({ required_error: t('Required') as string })
			.email(emailError)

		return getOptionalText(schema, state === 'required') as any
	}

	function url<T extends ValidateStatus>(
		state: T
	): ReturnCondition<T, z.ZodString> {
		const schema = z
			.string({
				required_error: t('Required') as string,
			})
			.url()

		return getOptionalText(schema, state === 'required') as any
	}

	function password<T extends ValidateStatus>(
		state: T
	): ReturnCondition<T, z.ZodString> {
		// TODO add password validation
		const schema = z
			.string({
				required_error: requiredError,
			})
			.trim()
			.min(8, passwordError)

		return getOptionalText(schema, state === 'required') as any
	}

	function updatePassword() {
		const schema = z
			.object({
				password: password('required'),
				repeatPassword: password('required'),
			})
			.refine(({ password, repeatPassword }) => password === repeatPassword, {
				message: notMatch,
				path: ['repeatPassword'],
			})
			.refine(({ password }) => PasswordValidator(password))

		return schema
	}

	function preferredContact() {
		const schema = z
			.object({
				email: email('optional'),
				phoneNumber: phone('optional'),
				contactMethod: z.enum(['phoneNumber', 'email']),
			})
			.refine(
				({ contactMethod, email, phoneNumber }) => {
					if (contactMethod === 'email') {
						return !!email
					} else if (contactMethod === 'phoneNumber') {
						return !!phoneNumber
					}

					return true
				},
				({ contactMethod }) => {
					if (contactMethod === 'email') {
						return {
							path: ['email'],
							message: emailError,
						}
					} else if (contactMethod === 'phoneNumber') {
						return {
							path: ['phoneNumber'],
							message: requiredError,
						}
					}

					return z.NEVER
				}
			)

		return schema
	}

	function phone<T extends ValidateStatus>(
		state: T
	): ReturnCondition<T, z.ZodString> {
		const baseSchema = z
			.string({
				required_error: requiredError,
			})
			.trim()
			.min(9, phoneError)

		if (state === 'required') {
			return baseSchema as any
		}

		const schema = baseSchema
			.or(z.string().trim().max(4, phoneError))
			.transform((value) => {
				if (value.length <= 4) {
					return undefined
				}

				return value
			})

		return getOptionalText(schema, false) as any
	}

	function select<T extends ValidateStatus>(
		state: T
	): ReturnCondition<T, z.ZodString> {
		const schema = z
			.string({ required_error: requiredError })
			.trim()
			.min(1, requiredError)

		return getOptionalText(schema, state === 'required') as any
	}

	function radio<T extends ValidateStatus>(
		state: T
	): ReturnCondition<T, z.ZodString> {
		const schema = z.string().trim().min(1, requiredError)

		return getOptionalText(schema, state === 'required') as any
	}

	function checkbox<T extends ValidateStatus>(
		state: T,
		errorMessage?: string
	): ReturnCondition<T, z.ZodBoolean> {
		return getOptionalCheckbox(
			errorMessage || requiredError,
			state === 'required'
		) as any
	}

	function files<T extends ValidateStatus>(
		state: T,
		isMultiple?: boolean
	): ReturnCondition<T, z.ZodArray<z.ZodTypeAny>> {
		return getOptionalFile(
			requiredError,
			state === 'required',
			isMultiple
		) as any
	}

	function image<T extends ValidateStatus>(
		state: T
	): ReturnCondition<T, z.ZodArray<z.ZodTypeAny>> {
		return getOptionalFile(requiredError, state === 'required') as any
	}

	const getLabel = (label: string, isMandatory?: boolean) =>
		isMandatory ? `${t(label)}*` : `${t(label)} (${t('optional')})`

	return {
		text,
		description,
		number,
		email,
		url,
		password,
		updatePassword,
		preferredContact,
		phone,
		select,
		radio,
		checkbox,
		files,
		image,
		getLabel,
	}
}
