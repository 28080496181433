import { useMedia } from './useMedia'
import { MEDIA } from '../utils'

export function useDevice() {
	const isMobile = useMedia(MEDIA.mobile)
	const isTablet = useMedia(MEDIA.tablet)

	switch (true) {
		case isMobile: {
			return 'mobile'
		}
		case isTablet: {
			return 'tablet'
		}
		default: {
			return 'desktop'
		}
	}
}
