export const ArrowLeft = ({ ...props }) => {
	return (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<g id="Ico_arrow.left_24">
				<path
					id="Vector"
					d="M10.7322 17.7931L5.23223 12.5431C5.08379 12.4015 4.99979 12.2053 4.99979 12C4.99979 11.7947 5.08379 11.5988 5.23223 11.4569L10.7322 6.20688C11.032 5.92173 11.5066 5.93248 11.7929 6.23228C12.079 6.52915 12.0682 7.00571 11.7675 7.2929L7.62059 11.2492L18.2507 11.2492C18.6647 11.2492 19.001 11.5854 19.001 11.9995C19.001 12.4135 18.6647 12.7492 18.2507 12.7492L7.62254 12.7492L11.7694 16.7054C12.0697 16.9931 12.0791 17.4697 11.7947 17.7675C11.5072 18.0675 11.0604 18.0781 10.7322 17.7931Z"
					fill="currentColor"
				/>
			</g>
		</svg>
	)
}
