import { z } from 'zod'
import { StoryblokTeamCardSchema } from './StoryblokTeamCard.schema'
import { TeamCard } from '../TeamCard'

type StoryblokTeamCardProps = {
	blok: z.TypeOf<typeof StoryblokTeamCardSchema>
	t: (key: string) => string
}

export function StoryblokTeamCard({
	blok: { image, mobile_phone, ...props },
	t,
}: StoryblokTeamCardProps) {
	return (
		<TeamCard
			image={image.filename}
			mobilePhone={mobile_phone}
			t={t}
			{...props}
		/>
	)
}
