import { ComponentPropsWithoutRef, forwardRef, ReactElement } from 'react'
import clsx from 'clsx'
import { FieldHint } from 'ui'

export interface CheckboxProps
	extends Omit<ComponentPropsWithoutRef<'input'>, 'type'> {
	label?: string | ReactElement
	hint?: string
	isInvalid?: boolean
	wrapperClass?: string
}

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
	(
		{ label, hint, className, wrapperClass, isInvalid, disabled, ...props },
		ref
	) => (
		<div className={clsx('flex', wrapperClass)}>
			<label
				className={clsx(
					'inline-flex flex-col',
					disabled ? 'cursor-not-allowed' : 'cursor-pointer'
				)}
			>
				<span className="inline-flex">
					<input
						ref={ref}
						type="checkbox"
						disabled={disabled}
						className={clsx(
							'border-primary h-6 w-6 cursor-pointer rounded-lg border-2 bg-transparent',
							'active:border-primary',
							'checked:bg-primary checked:border-primary checked:hover:bg-primary text-primary',
							'focus:shadow-inner-focus focus:ring-transparent focus:checked:shadow-none',
							'disabled:bg-gray-05 disabled:border-gray-05 disabled:checked:bg-disabled-checkbox disabled:hover:bg-gray-05 disabled:cursor-not-allowed',
							isInvalid
								? '!text-status-error !border-status-error checked:bg-status-error checked:hover:bg-status-error'
								: 'focus:checked:bg-primary focus:checked:border-primary',
							className && className
						)}
						{...props}
					/>
					{label && (
						<span
							className={clsx(
								'p_highlight ml-3 self-center',
								disabled && 'text-gray-25',
								isInvalid && 'text-status-error'
							)}
						>
							{label}
						</span>
					)}
				</span>
				{hint && (
					<FieldHint text={hint} isInvalid={isInvalid} className="ml-9" />
				)}
			</label>
		</div>
	)
)
