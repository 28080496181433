import { useEffect } from 'react'
import { Router } from 'next/router'

export const useAlertIfUnsavedChanges = (
	hasUnsavedChanges: boolean,
	t: (value: string) => string,
	message?: string
) => {
	const fallbackMessage = t('Warning! You have unsaved changes.')

	useEffect(() => {
		if (hasUnsavedChanges) {
			const routeChangeStart = () => {
				const ok = confirm(message || fallbackMessage)

				if (!ok) {
					Router.events.emit('routeChangeError')
					throw 'Abort route change. Please ignore this error.'
				}
			}

			Router.events.on('routeChangeStart', routeChangeStart)

			return () => {
				Router.events.off('routeChangeStart', routeChangeStart)
			}
		}
	}, [hasUnsavedChanges, message, fallbackMessage])
}
