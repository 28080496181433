import { useEffect, useRef } from 'react'
import { useIsomorphicLayoutEffect } from 'common'

type Target = Pick<Document, 'addEventListener' | 'removeEventListener'>

export const useKeyboard = (
	eventKey: string,
	callback: (event: KeyboardEvent) => void,
	target?: Target
) => {
	const savedCallback = useRef(callback)

	// Remember the latest callback if it changes.
	useIsomorphicLayoutEffect(() => {
		savedCallback.current = callback
	}, [callback])

	useEffect(() => {
		const getTarget = target || document

		const handleKeydown = (event: KeyboardEvent) => {
			event.stopPropagation()

			if (event.key === eventKey) {
				savedCallback.current(event)
			}
		}

		getTarget.addEventListener('keydown', handleKeydown)

		return () => {
			getTarget.removeEventListener('keydown', handleKeydown)
		}
	}, [eventKey, target])
}
