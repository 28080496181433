import clsx from 'clsx'
import { parseRichtext } from 'common'
import { SectionHeader } from '../SectionHeader'
import { StoryblokSectionHeaderProps } from './StoryblokSectionHeader.data'
import { Noop } from '../../../atoms'
import { AnimationBlock } from '../../../molecules'

export const StoryblokSectionHeader = ({
	blok,
	className,
	type = 'standalone',
	theme,
}: StoryblokSectionHeaderProps) => {
	if (!blok) {
		return <Noop />
	}

	const {
		secondary_button_text,
		image_desktop,
		image_tablet,
		image_mobile,
		scroll_id,
		primary_button_text,
		primary_button_url,
		secondary_text,
		secondary_button_url,
		...rest
	} = blok

	const description = parseRichtext(secondary_text)

	const isStandalone = type === 'standalone'

	return (
		<AnimationBlock
			enabled={isStandalone}
			className={clsx(isStandalone && 'section')}
		>
			<SectionHeader
				type={type}
				className={className}
				primaryButtonText={primary_button_text}
				primaryButtonUrl={primary_button_url}
				secondaryButtonText={secondary_button_text}
				secondaryButtonUrl={secondary_button_url}
				imageMobile={image_mobile?.filename as string | undefined}
				imageTablet={image_tablet?.filename as string | undefined}
				imageDesktop={image_desktop?.filename as string | undefined}
				scrollId={scroll_id}
				description={description}
				theme={theme}
				{...rest}
			/>
		</AnimationBlock>
	)
}
