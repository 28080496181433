import { ReactNode } from 'react'
import { i18n } from 'next-i18next'
import { createStore } from 'common'
import { getHostByCountryCode } from 'configs'
import { CountryCode } from 'constants/language'

type DomainStore = {
	host: string
	origin: string
	url: string
	countryCode: CountryCode
}

type DomainStoreProps = DomainStore & {
	children: ReactNode
}

export const useDomain = () => {
	const initialLocale = i18n?.language

	const [_lang, _countryCode] = initialLocale?.toLowerCase().split('-') ?? []

	const countryCode = _countryCode === 'at' ? 'sportsaustria' : _countryCode

	const host = getHostByCountryCode(countryCode as string)
	const origin = `https://${host}`

	return {
		host,
		origin,
		countryCode: countryCode as CountryCode,
		initialLocale,
	}
}

export const [DomainStoreProvider, useDomainStore] = createStore<
	DomainStore,
	DomainStoreProps
>('DomainStore', (props) => {
	// Example:
	// host: "nl.dev.trenkwalder.com"
	// origin: "https://nl.dev.trenkwalder.com"
	// countryCode: "nl"
	// url: "https://nl.dev.trenkwalder.com/en-NL/jobs"

	return props
})
