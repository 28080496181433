import { ReactNode } from 'react'
import { z } from 'zod'
import clsx from 'clsx'
import { Container, Noop } from 'ui'
import DynamicComponent from '@components/common/DynamicComponents/DynamicComponent'
import { GridSchema } from './Grid.schema'

type GridProps = {
	children: ReactNode
	blok?: z.TypeOf<typeof GridSchema>
}

const Grid = ({ children, blok }: GridProps) => {
	const displayContent = () => {
		if (children) {
			return children
		}

		if (!blok) {
			return <Noop />
		}

		return blok.children.map((child, index) => {
			return (
				<div
					key={child._uid}
					className={clsx(
						`col-span-${blok.number_of_columns_desktop}`,
						!blok.number_of_columns_tablet
							? 'md:col-span-6'
							: `md:col-span-${blok.number_of_columns_tablet}`,
						!blok.number_of_columns_mobile
							? 'sm:col-span-full'
							: `sm:col-span-${blok.number_of_columns_mobile}`
					)}
				>
					<DynamicComponent
						blok={child}
						columnsConfig={{
							desktop: blok.number_of_columns_desktop,
							tablet: blok.number_of_columns_tablet,
						}}
						index={index}
					/>
				</div>
			)
		})
	}

	return (
		<Container className={clsx('section', blok?.isNarrow && 'max-w-[680px]')}>
			{blok?.title && <h2 className="pb-6">{blok.title}</h2>}
			<div
				className="mx-auto grid h-full auto-cols-fr grid-cols-12 justify-items-center gap-6 [&_>_div]:w-full"
				id={blok?.scroll_id}
			>
				{displayContent()}
			</div>
		</Container>
	)
}

export default Grid
