import { useReducer } from 'react'
import clsx from 'clsx'
import { AbstractButton, MediaPlayer, OptimizedImage } from 'ui'
import VideoModal from '../Overlays/VideoModal'

export interface VideoCardProps {
	videoId: string
	title?: string
	image?: string | null
	isImageGallery?: boolean
	scrollId?: string
}

export const VideoCard = ({
	videoId,
	title,
	image,
	scrollId,
	isImageGallery,
}: VideoCardProps) => {
	const [showModal, toggleShowModal] = useReducer((state) => !state, false)

	return (
		<>
			<VideoModal
				videoId={videoId}
				onClose={toggleShowModal}
				isOpened={showModal}
			/>
			<AbstractButton
				onClick={toggleShowModal}
				className={clsx(
					'bg-primary relative w-full',
					isImageGallery ? 'w-full pt-[100%]' : 'pt-[56.25%]'
				)}
				{...(scrollId && { id: scrollId })}
			>
				{image && (
					<OptimizedImage
						src={image}
						alt={title ?? 'Show video modal'}
						className="object-cover opacity-60 blur-[2px]"
						fill
					/>
				)}
				<div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 p-4">
					<MediaPlayer className="mx-auto text-white" />
					{title && (
						<h4 className="subtitle relative mt-2 text-white">{title}</h4>
					)}
				</div>
			</AbstractButton>
		</>
	)
}
