import { ComponentPropsWithoutRef, ReactNode, useRef } from 'react'
import { motion, useScroll, useTransform } from 'framer-motion'

type SectionProps = {
	children: ReactNode
	enabled?: boolean
} & ComponentPropsWithoutRef<'div'>

export function AnimationBlock({
	children,
	className,
	enabled = true,
	...props
}: SectionProps) {
	const target = useRef<HTMLDivElement>(null)
	const { scrollYProgress } = useScroll({
		target,
		offset: ['10vh end', 'end 10vh'],
	})

	const opacity = useTransform(scrollYProgress, [0, 0.2, 0.8, 1], [0, 1, 1, 0])

	const scale = useTransform(
		scrollYProgress,
		[0, 0.2, 0.8, 1],
		[0.95, 1, 1, 0.95]
	)

	if (!enabled) {
		return <>{children}</>
	}

	return (
		<motion.div
			ref={target}
			className={className}
			id={props.id}
			{...(enabled && {
				style: {
					opacity,
					scale,
				},
			})}
		>
			{children}
		</motion.div>
	)
}
