import { useMemo } from 'react'
import { useRouter } from 'next/router'
import { TFunction } from 'next-i18next'
import { unslugify, titleize } from '../utils'

export const usePageTitle = (t: TFunction, countryName: string) => {
	const { asPath } = useRouter()

	const pageTitle = useMemo(() => {
		const [withoutQuery] = asPath.split('?')
		const [withoutAnchor] = (withoutQuery as string).split('#')
		const paths = (withoutAnchor as string).split('/').filter(Boolean)

		const currentPage = paths[paths.length - 1] ?? ''

		const decodedCurrentPage = decodeURIComponent(currentPage)

		const pages = t<string, Record<string, string>>('pageTitle', {
			returnObjects: true,
		})

		const pageName =
			pages?.[decodedCurrentPage] ?? titleize(unslugify(decodedCurrentPage))

		const base = `${countryName} Trenkwalder`

		return [pageName, base].filter(Boolean).join(' | ')
	}, [asPath, countryName, t])

	return pageTitle
}
