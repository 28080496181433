import { ComponentPropsWithoutRef } from 'react'
import { StoryblokRichtext } from 'storyblok-rich-text-react-renderer'
import { RichTextResolverService } from 'service'
import { Noop } from 'ui'

interface Richtext extends ComponentPropsWithoutRef<'div'> {
	text?: StoryblokRichtext
}

export function Richtext({ text, ...props }: Richtext) {
	if (!text) {
		return <Noop />
	}

	const parsedText = new RichTextResolverService(text).baseResolve()

	return <div {...props}>{parsedText}</div>
}
