/* eslint-disable prefer-const */
import { useMemo } from 'react'
import { z } from 'zod'
import { useSchema } from 'common'
import { FormItem, RichTextResolverService } from 'service'
import { useTranslation } from 'next-i18next'

type Options = {
	language?: string
}

export const useDynamicFormSchema = (
	formData: Array<FormItem> = [],
	options: Options = {}
) => {
	const { language } = options

	let { t, i18n } = useTranslation()

	if (language) {
		t = i18n.getFixedT(language)
	}

	const { text, number, email, phone, checkbox, radio, select, image, files } =
		useSchema(t)

	const schemaLookup = (
		isRequired: boolean
	): Record<FormItem['type'], z.ZodTypeAny | null> => {
		const state = isRequired ? 'required' : 'optional'

		return {
			text: text(state),
			textarea: text(state),
			email: email(state),
			phone: phone(state),
			number: number(state),
			checkbox: checkbox(state),
			'modal-checkbox': checkbox(state),
			radio: radio(state),
			select: select(state),
			cv: files(state),
			documents: files(state, true),
			image: image(state),
			title: null,
			info: null,
			multiupload: files(state),
			calendar: text(state),
		}
	}

	const getSchema = useMemo(
		() =>
			formData.reduce((acc, formElement) => {
				const { label, type, required, options, max_length } = formElement
				let { api_field_name } = formElement

				const labelCamelCase = new RichTextResolverService(
					label
				).resolveToCamelCase()
				api_field_name ||= labelCamelCase

				if (type === 'info') {
					return acc
				}
				if (type === 'title') {
					return acc
				}
				if (type === 'checkbox' && options.length > 0) {
					const checkboxOptions = options.reduce((checkboxAcc, { value }) => {
						return {
							...checkboxAcc,
							[value]: checkbox('optional').describe('from options!'),
						}
					}, {})

					return {
						...acc,
						...checkboxOptions,
					}
				}
				if (type === 'number' && max_length) {
					const state = required ? 'required' : 'optional'
					const schema = number(state, max_length)

					return {
						...acc,
						[api_field_name]: schema,
					}
				}

				const schema = schemaLookup(required)[type] ?? z.any()

				return {
					...acc,
					[api_field_name]: schema,
				}
			}, {}),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[formData]
	)

	return getSchema as z.ZodRawShape
}
