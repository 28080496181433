export const getScrollbarWidth = (): number => {
	const outer = document.createElement('div')
	const { body } = document

	outer.style.visibility = 'hidden'
	outer.style.overflow = 'scroll'

	body.appendChild(outer)

	const inner = document.createElement('div')
	outer.appendChild(inner)

	const scrollbarWidth = outer.offsetWidth - inner.offsetWidth

	body.removeChild(outer)

	return scrollbarWidth
}
