import { z } from 'zod'
import { DatasourceTheme, ImageOptionalSchema, RichTextRequiredSchema } from 'schema'

export const StoryblokImageGallerySchema = z.object({
	tag: z.string().optional(),
	title: z.string().trim().min(1),
	subtitle: z.string().optional(),
	secondary_text: RichTextRequiredSchema,
	primary_button_text: z.string().optional(),
	primary_button_url: z.string().optional(),
	secondary_button_text: z.string().optional(),
	secondary_button_url: z.string().optional(),
	video_id: z.string().optional(),
	video_title: z.string().optional(),
	scroll_id: z.string().optional(),
	image: ImageOptionalSchema,
	hide_show_more_button: z.boolean().optional(),
	image_position: z.enum(['1', '2']).optional(),
	theme: DatasourceTheme.optional(),
})
