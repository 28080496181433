import Script from 'next/script'
import { isProduction, isLocal } from 'configs'
import { Noop } from '../Noop'

interface GTMProps {
	gtm: {
		google_tag_manager_dev_staging: string
		google_tag_manager_prod: string
	}
}

export const GTM = ({ gtm }: GTMProps) => {
	if (isLocal) {
		return <Noop />
	}

	if (!gtm) {
		return <Noop />
	}

	const { google_tag_manager_dev_staging, google_tag_manager_prod } = gtm

	let id = google_tag_manager_dev_staging

	if (isProduction) {
		id = google_tag_manager_prod
	}

	return (
		<>
			<Script
				id="google-tag-manager"
				strategy="afterInteractive"
				dangerouslySetInnerHTML={{
					__html: `(function (w, d, s, l, i) {
                w[l] = w[l] || []; w[l].push({
                    'gtm.start':
                        new Date().getTime(), event: 'gtm.js'
                }); var f = d.getElementsByTagName(s)[0],
                    j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src =
                        'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f);
            })(window, document, 'script', 'dataLayer', '${id}');`,
				}}
			/>
			<noscript>
				<iframe
					src={`https://www.googletagmanager.com/ns.html?id=${id}`}
					height="0"
					width="0"
					style={{ display: 'none', visibility: 'hidden' }}
				/>
			</noscript>
		</>
	)
}
