import { ComponentPropsWithoutRef, ReactNode, useReducer } from 'react'
import clsx from 'clsx'
import { Variants, motion } from 'framer-motion'
import { AbstractButton, Plus } from '../../atoms'

const variants: Variants = {
	show: {
		height: 'auto',
	},
	hide: {
		height: 0,
		transition: {
			type: 'tween',
			duration: 0.15,
		},
	},
}

type AccordionProps = {
	title: string
	titleStyle?: string
	children: ReactNode
} & ComponentPropsWithoutRef<'div'>

export const Accordion = ({
	title,
	titleStyle,
	children,
	className,
}: AccordionProps) => {
	const [isOpened, toggleIsOpened] = useReducer((state) => !state, false)

	return (
		<div className={clsx('flex flex-col bg-white', className)}>
			<AbstractButton
				className={clsx(
					'flex items-center justify-between rounded-sm py-6 pl-6 pr-4 text-left transition-colors',
					isOpened ? 'bg-primary text-white' : 'hover:bg-gray-10 bg-white'
				)}
				onClick={toggleIsOpened}
			>
				<p className={clsx('word_break', titleStyle || 'subtitle_highlight')}>
					{title}
				</p>
				<motion.span
					className="ml-2 shrink-0"
					animate={{
						rotate: isOpened ? 45 : 0,
					}}
				>
					<Plus />
				</motion.span>
			</AbstractButton>
			<motion.div
				variants={variants}
				animate={isOpened ? 'show' : 'hide'}
				initial="hide"
				className="overflow-hidden"
			>
				<div className="px-8 py-4">{children}</div>
			</motion.div>
		</div>
	)
}
