import { ComponentPropsWithoutRef } from 'react'
import clsx from 'clsx'

interface FieldHintProps extends ComponentPropsWithoutRef<'span'> {
	text: string
	isInvalid?: boolean
}

export const FieldHint = ({
	text,
	isInvalid,
	className,
	...props
}: FieldHintProps) => (
	<span
		className={clsx(
			'micro mt-2 inline-block',
			isInvalid ? 'text-status-error' : 'text-gray-70',
			className && className
		)}
		{...props}
	>
		{text}
	</span>
)
