import { ReactNode, forwardRef, useMemo } from 'react'
import clsx from 'clsx'
import { Spinner } from '../Icons'
import { AbstractButton, ElementType, NativeProps } from '../AbstractButton'

export type IconPosition = 'left' | 'right'

export type ButtonVariant = 'primary' | 'secondary' | 'tertiary'
export type ButtonTheme = 'dark' | 'light' | 'colored'

export interface ButtonProps extends NativeProps {
	variant?: ButtonVariant
	theme?: ButtonTheme
	icon?: ReactNode
	iconPosition?: IconPosition
	children?: ReactNode
	isDisabled?: boolean
	isLoading?: boolean
	isLarge?: boolean
}

const mapPrimaryClasses: Record<ButtonTheme, string> = {
	colored:
		'bg-primary text-white border-primary hover:bg-primary-hover hover:border-primary-hover active:bg-primary-pressed active:border-primary-pressed',
	dark: 'bg-gray-90 text-white border-gray-90 hover:bg-gray-85 hover:border-gray-85 active:bg-native-black active:border-native-black',
	light:
		'bg-white text-primary border-white hover:bg-gray-05 hover:border-gray-05 active:bg-gray-10 active:border-gray-10',
}

const primaryDisabledClasses =
	'bg-gray-10 border-gray-10 text-gray-25 cursor-not-allowed outline-gray-25'

const mapSecondaryClasses: Partial<Record<ButtonTheme, string>> = {
	colored:
		'text-gray-90 border-gray-90 hover:text-gray-70 hover:border-gray-70 active:text-black active:border-black',
	dark: 'hover:text-primary-hover active:text-primary-pressed hover:border-gray-25 active:border-gray-55',
	light:
		'text-white border-white hover:text-gray-05 hover:border-gray-05 active:text-gray-10 active:border-gray-10',
}

const secondaryDisabledClasses =
	'text-gray-25 border-gray-10 cursor-not-allowed outline-gray-25'

const mapTertiaryClasses: Partial<Record<ButtonTheme, string>> = {
	colored:
		'text-gray-90 border-transparent hover:text-gray-70 hover:bg-base-color-alpha/[0.08] active:text-black active:bg-base-color-alpha/[0.08]',
	dark: 'border-transparent hover:text-primary-hover active:text-primary-pressed',
	light:
		'text-white border-transparent hover:text-gray-05 hover:bg-black-alpha/[0.04] active:text-gray-10 active:bg-black-alpha/[0.16]',
}

const tertiaryDisabledClasses =
	'text-gray-25 border-transparent cursor-not-allowed outline-gray-25'

export const Button = forwardRef<ElementType, ButtonProps>(
	(
		{
			children,
			className,
			theme,
			variant,
			icon,
			iconPosition,
			isLarge,
			isDisabled,
			isLoading,
			...props
		},
		ref
	) => {
		const showDisabledClass = isDisabled || props.disabled

		const getThemeClasses = useMemo(() => {
			switch (variant) {
				case 'secondary': {
					if (showDisabledClass) {
						return secondaryDisabledClasses
					}

					return mapSecondaryClasses[theme || 'colored']
				}
				case 'tertiary': {
					if (showDisabledClass) {
						return tertiaryDisabledClasses
					}

					return mapTertiaryClasses[theme || 'colored']
				}
				default: {
					if (showDisabledClass) {
						return primaryDisabledClasses
					}

					return mapPrimaryClasses[theme || 'dark']
				}
			}
		}, [variant, showDisabledClass, theme])

		return (
			<AbstractButton
				ref={ref}
				className={clsx(
					'p_highlight flex items-center justify-center rounded-full border-2 p-2.5 text-center transition duration-150 ease-in-out',
					isLoading && 'cursor-wait',
					isLarge && 'h-[104px]',
					getThemeClasses,
					className
				)}
				{...props}
			>
				{isLoading ? (
					<Spinner />
				) : (
					<>
						{icon && iconPosition !== 'right' && icon}
						{!!children && (
							<span className={clsx('block px-2')}>{children}</span>
						)}
						{icon && iconPosition === 'right' && icon}
					</>
				)}
			</AbstractButton>
		)
	}
)
