import { ReactElement, ReactNode } from 'react'
import { storyblokEditable } from '@storyblok/react'
import { z } from 'zod'
import { AnimationBlock, Container, StoryblokSectionHeader } from 'ui'
import { useDevice } from 'common'
import { StoryblokSliderSchema } from './StoryblokSlider.schema'
import { Config, Slider } from '..'

export type StoryblokSliderProps = {
	config?: Config
	withShadows?: boolean
	blok: z.TypeOf<typeof StoryblokSliderSchema>
	children: (item: any) => ReactNode
}

const perViewLookup: Record<
	string,
	Record<ReturnType<typeof useDevice>, number>
> = {
	small_card: {
		mobile: 1,
		tablet: 2,
		desktop: 2,
	},
}

export const StoryblokSlider = ({
	blok,
	withShadows,
	config,
	children,
}: StoryblokSliderProps) => {
	const device = useDevice()

	const getPerView = () => {
		if (!blok.items?.length) {
			return 1
		}

		if (config?.perView) {
			return config?.perView
		}

		const componentName = blok.items[0].component
		const componentPerView = perViewLookup[componentName]

		if (componentPerView) {
			return componentPerView[device]
		}

		return 1
	}

	const header = blok?.header?.[0]

	return (
		<AnimationBlock
			className="section overflow-hidden"
			{...storyblokEditable(blok)}
		>
			<Container>
				<Slider
					count={blok.items.length}
					config={{
						...config,
						perView: getPerView(),
					}}
				>
					{header && <StoryblokSectionHeader blok={header} type="nested" />}
					<Slider.Track withShadows={withShadows}>
						{
							blok.items?.map((item) => (
								<Slider.Slide key={item._uid}>{children(item)}</Slider.Slide>
							)) as Array<ReactElement>
						}
					</Slider.Track>
					<div className="flex items-center justify-between">
						<Slider.Arrows />
						<Slider.Dots />
					</div>
				</Slider>
			</Container>
		</AnimationBlock>
	)
}
