import {
	ComponentPropsWithoutRef,
	forwardRef,
	ExoticComponent,
	ElementType as BaseElementType,
} from 'react'
import clsx from 'clsx'
import Link from 'next/link'

export type NativeProps = ComponentPropsWithoutRef<'button'> &
	ComponentPropsWithoutRef<'a'> & {
		asElement?: BaseElementType
	}
export type ElementType = HTMLButtonElement | HTMLAnchorElement

interface AbstractButtonProps extends NativeProps {
	component?: ExoticComponent<any>
}

const protocolRegex = new RegExp(/^(mailto:|tel:|https:\/\/|http:\/\/)/)

export const AbstractButton = forwardRef<ElementType, AbstractButtonProps>(
	({ children, className, component = Link, asElement, ...props }, ref) => {
		let Component: 'a' | 'button' | typeof component | typeof asElement =
			'button'
		const { href, type } = props

		const isInnerLink = href && !protocolRegex.test(href)

		const componentProps = { ...props } as any

		if (asElement) {
			Component = asElement
		} else if (isInnerLink) {
			Component = component
		} else if (href) {
			Component = 'a'

			componentProps.target = '_blank'
			componentProps.rel = 'noopener'
		} else if (type == null) {
			componentProps.type = 'button'
		}

		return (
			<Component
				ref={ref}
				className={clsx(
					Component === 'button' && 'disabled:cursor-not-allowed',
					className && className
				)}
				{...componentProps}
			>
				{children}
			</Component>
		)
	}
)
