import { ComponentPropsWithoutRef } from 'react'
import clsx from 'clsx'
import { XMark, AbstractButton } from 'ui'
import { convertFileSize } from 'common'

type FileType = {
	content: string
	fileName: string
}
interface FilePreviewProps extends ComponentPropsWithoutRef<'div'> {
	file: FileType
	onRemove: (file: FileType) => void
}

const FilePreview = ({ file, onRemove, className }: FilePreviewProps) => {
	const { fileName, content } = file

	return (
		<div
			className={clsx(
				'bg-status-correct flex w-full items-center justify-between rounded-sm px-2 py-3 text-white',
				className && className
			)}
			title={fileName}
		>
			<p className="p_highlight mx-2 truncate">
				{fileName} ({convertFileSize(content.length)})
			</p>
			<AbstractButton
				onClick={() => onRemove(file)}
				aria-label={`Remove ${fileName}`}
			>
				<XMark className="pointer-events-none" />
			</AbstractButton>
		</div>
	)
}
export default FilePreview
