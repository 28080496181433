import { ComponentPropsWithoutRef } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import MultiUpload from '../MultiUpload'

interface ControlledMultiUploadProps extends ComponentPropsWithoutRef<'div'> {
	name: string
}

const ControlledMultiUpload = ({
	name,
	...props
}: ControlledMultiUploadProps) => {
	const { control } = useFormContext()

	return (
		<Controller
			name={name}
			control={control}
			render={() => <MultiUpload {...props} />}
		/>
	)
}

export default ControlledMultiUpload
