import { ComponentPropsWithoutRef, ReactElement } from 'react'
import { useFormContext } from 'react-hook-form'
import clsx from 'clsx'

interface FormErrorProps extends ComponentPropsWithoutRef<'p'> {
	message?: string
	t: (value: string) => string
}

export const FormError = ({
	t,
	message,
	className,
	...props
}: FormErrorProps): ReactElement => {
	const {
		formState: { isValid, isSubmitted },
	} = useFormContext()

	const showError = !isValid && isSubmitted
	const errorMessage = message ?? t('Please fill out all mandatory fields.')

	return (
		<p
			className={clsx(
				'p_highlight',
				showError && 'text-status-error',
				className && className
			)}
			suppressHydrationWarning
			{...props}
		>
			{showError ? errorMessage : t('*Mandatory fields')}
		</p>
	)
}
