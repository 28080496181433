/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable @next/next/no-img-element */
import { forwardRef, DetailedHTMLProps, ImgHTMLAttributes } from 'react'
import Head from 'next/head'
import { useOptimizedImage } from './useOptimizedImage'

interface ImageProps
	extends Omit<
		DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>,
		'ref' | 'height' | 'width' | 'src' | 'srcSet' | 'loading' | 'alt'
	> {
	src: string
	alt: string
	priority?: boolean
	fill?: boolean
	height?: number
	width?: number
}

export const OptimizedImage = forwardRef<HTMLImageElement, ImageProps>(
	(
		{ src, alt, height, width, fill, priority, sizes, style, ...props },
		ref
	) => {
		const { src: optimizedSrc, srcSet } = useOptimizedImage({
			src,
			fill,
			width,
		})

		return (
			<>
				{priority && (
					<Head>
						<link
							rel="preload"
							as="image"
							imageSrcSet={srcSet}
							imageSizes={sizes || '100vw'}
							// @ts-ignore
							fetchpriority="high"
						/>
					</Head>
				)}
				<img
					ref={ref}
					alt={alt}
					decoding="async"
					height={height}
					width={width}
					src={optimizedSrc}
					srcSet={srcSet}
					sizes={sizes}
					style={{
						color: 'transparent',
						...(fill && {
							position: 'absolute',
							height: '100%',
							width: '100%',
							left: 0,
							top: 0,
							right: 0,
							bottom: 0,
						}),
						...style,
					}}
					{...(priority
						? {
								fetchpriority: 'high',
						  }
						: {
								loading: 'lazy',
						  })}
					{...props}
				/>
			</>
		)
	}
)
