import { breakpoints } from 'configs'

const getBreakpointMedia = (breakpoint?: number, isMax?: boolean) => {
	if (!breakpoint) {
		return ''
	}

	if (isMax) {
		return `(max-width: ${breakpoint - 0.02}px)`
	}

	return `(min-width: ${breakpoint}px)`
}

export const MEDIA = {
	xsMobile: getBreakpointMedia(breakpoints.get('small'), true),
	mobile: getBreakpointMedia(breakpoints.get('medium'), true),
	tablet: `${getBreakpointMedia(
		breakpoints.get('medium')
	)} and ${getBreakpointMedia(breakpoints.get('large'), true)}`,
	desktop: getBreakpointMedia(breakpoints.get('large')),
	xlDesktop: getBreakpointMedia(breakpoints.get('xLarge')),
}
