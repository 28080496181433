import { ReactElement } from 'react'
import { RichTextResolverService } from 'service'
import { StoryblokRichtext } from 'storyblok-rich-text-react-renderer'

interface FormTitleProps {
	label: StoryblokRichtext
}

export const FormTitle = ({ label }: FormTitleProps): ReactElement => {
	return (
		<div className="[&_*]:title col-span-full mb-12 mt-4 sm:mb-10">
			{new RichTextResolverService(label).baseResolve()}
		</div>
	)
}
