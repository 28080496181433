import { ComponentPropsWithoutRef, forwardRef, ReactElement } from 'react'
import clsx from 'clsx'
import { ChevronDown } from '../Icons'
import { FieldLabel } from '../FieldLabel'
import { FieldHint } from '../FieldHint'

export interface SelectProps extends ComponentPropsWithoutRef<'select'> {
	options: Readonly<Array<{ label: string | number; value: string | number }>>
	label?: string
	hint?: string
	isInvalid?: boolean
	icon?: ReactElement
	wrapperClass?: string
	labelClassName?: string
}

export const Select = forwardRef<HTMLSelectElement, SelectProps>(
	(
		{
			options,
			label,
			hint,
			className,
			wrapperClass,
			labelClassName,
			placeholder,
			isInvalid,
			disabled,
			icon = <ChevronDown />,
			...props
		},
		ref
	) => (
		<div className={clsx('inline-block', wrapperClass && wrapperClass)}>
			<label className="relative block">
				{label && <FieldLabel text={label} className={labelClassName} />}
				<span className="relative block w-full">
					<select
						ref={ref}
						disabled={disabled}
						defaultValue={placeholder && !props.value ? '' : undefined}
						className={clsx(
							'border-gray-05 w-full cursor-pointer rounded-full border bg-none py-3 pl-4 pr-12',
							'hover:border-gray-25',
							'focus:border-primary focus:ring-0',
							'disabled:bg-gray-05 disabled:text-gray-25 disabled:border-gray-05 disabled:hover:border-gray-05 disabled:cursor-not-allowed disabled:opacity-100',
							isInvalid && '!border-status-error text-status-error',
							disabled ? 'text-gray-70' : 'text-primary',
							className && className
						)}
						{...props}
					>
						{placeholder && (
							<option value="" disabled>
								{placeholder}
							</option>
						)}
						{options.map(({ label, value }) => (
							<option key={label} value={value}>
								{label}
							</option>
						))}
					</select>
					{icon && (
						<span
							className={clsx(
								'pointer-events-none absolute right-4 top-1/2 -translate-y-1/2',
								!isInvalid && !disabled && 'text-primary',
								isInvalid && !disabled && 'text-status-error',
								disabled && 'text-gray-25'
							)}
						>
							{icon}
						</span>
					)}
				</span>
				{hint && <FieldHint text={hint} isInvalid={isInvalid} />}
			</label>
		</div>
	)
)
