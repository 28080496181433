import { StoryblokRichtext } from 'storyblok-rich-text-react-renderer'
import { RichTextResolverService } from 'service'

export const parseRichtext = (text?: StoryblokRichtext | string) => {
	if (!text) {
		return <></>
	}

	if (typeof text === 'string') {
		return <p>{text}</p>
	}

	return new RichTextResolverService(text).baseResolve()
}
