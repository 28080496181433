import { ComponentPropsWithoutRef, useMemo } from 'react'
import { motion } from 'framer-motion'
import clsx from 'clsx'
import { AbstractButton, Ellipse } from 'ui'
import { Theme } from './Dots.data'

const DOT_SIZE = 12
const DOT_SPACING = 2

const DOTS_LIMIT = 4

interface DotsProps extends Omit<ComponentPropsWithoutRef<'div'>, 'onClick'> {
	count: number
	active: number
	onClick: (dot: number) => void
	theme?: Theme
}

export function Dots({
	count,
	active,
	onClick,
	className,
	theme = 'default',
	...props
}: DotsProps) {
	// Generate dots to start from number 1. eg. count is 4 --> [1, 2, 3, 4]
	const dots = useMemo(
		() =>
			Array.from(
				{
					length: count,
				},
				(_, index) => index + 1
			),
		[count]
	)

	const getX = () => {
		// Don't translate dots if count is less than limit
		if (count <= DOTS_LIMIT) {
			return 0
		}

		// Don't translate dots until 4th is active
		if (active <= 3) {
			return 0
		}

		// Don't translate dots if last two are active
		if (active >= count - 1) {
			return -(DOT_SIZE + DOT_SPACING) * (count - 4)
		}

		// Translate dots based on active
		return -(DOT_SIZE + DOT_SPACING) * (active - 3)
	}

	const getScale = (current: number) => {
		// Don't scale dots if count is less than limit
		if (count <= DOTS_LIMIT) {
			return 1
		}

		if (active <= 3) {
			// Don't scale first three dots
			return current < DOTS_LIMIT ? 1 : 0.5
		}

		if (active >= count - 1) {
			// Don't scale last three dots
			return current > count - 3 ? 1 : 0.5
		}

		const isNext = current >= active + 1
		const isSecondToPrevious = current <= active - 2

		// Scale next dot and second to previous dot
		return isNext || isSecondToPrevious ? 0.5 : 1
	}

	return (
		<div className={clsx('max-w-[56px] overflow-hidden', className)} {...props}>
			<motion.div
				className="flex gap-0.5"
				animate={{
					x: getX(),
				}}
			>
				{dots.map((dot) => {
					const isActive = active === dot

					return (
						<motion.div
							key={dot}
							className="flex"
							animate={{
								scale: getScale(dot),
							}}
						>
							<AbstractButton
								aria-label={`Slide number ${dot}`}
								onClick={() => onClick(dot)}
								className="p-0.5"
							>
								<Ellipse
									className={clsx(
										'transition-colors',
										isActive
											? clsx(
													theme === 'default' && 'text-primary',
													theme === 'primary' && 'text-primary',
													theme === 'light' && 'text-white'
											  )
											: clsx(
													theme === 'default' && 'text-black-alpha/[0.24]',
													theme === 'primary' && 'text-white/[0.24]',
													theme === 'light' && 'text-black-alpha/[0.24]'
											  )
									)}
								/>
							</AbstractButton>
						</motion.div>
					)
				})}
			</motion.div>
		</div>
	)
}
