import { z } from 'zod'
import { RichTextOptionalSchema, ImageOptionalSchema } from 'schema'

export const StoryblokSectionHeaderSchema = z.object({
	tag: z.string().optional(),
	title: z.string().trim().min(1),
	secondary_text: RichTextOptionalSchema,
	image_desktop: ImageOptionalSchema,
	image_tablet: ImageOptionalSchema,
	image_mobile: ImageOptionalSchema,
	scroll_id: z.string().optional(),
	primary_button_text: z.string().optional(),
	primary_button_url: z.string().optional(),
	secondary_button_text: z.string().optional(),
	secondary_button_url: z.string().optional(),
})
