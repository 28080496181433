import clsx from 'clsx'
import { ComponentProps } from 'react'

export const Spinner = ({ className, ...props }: ComponentProps<'svg'>) => (
	<svg
		className={clsx('animate-spin cursor-wait', className)}
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M12 5.08C12 4.48353 12.4857 3.99251 13.0767 4.07279C14.1229 4.21489 15.1343 4.5632 16.0508 5.1014C17.279 5.82258 18.2924 6.85856 18.9863 8.10234C19.6802 9.34612 20.0295 10.7526 19.9981 12.1765C19.9666 13.6004 19.5556 14.9901 18.8075 16.2021C18.0594 17.414 17.0014 18.4043 15.7426 19.0706C14.4838 19.7369 13.0699 20.055 11.6471 19.9922C10.2242 19.9294 8.84391 19.4878 7.64875 18.7132C6.75683 18.1351 5.99216 17.3871 5.39623 16.5156C5.05955 16.0232 5.2808 15.3689 5.814 15.1016C6.3472 14.8342 6.98895 15.0579 7.34978 15.5329C7.75665 16.0684 8.25466 16.5319 8.82359 16.9006C9.69605 17.4661 10.7037 17.7884 11.7424 17.8343C12.7811 17.8802 13.8132 17.6479 14.7321 17.1615C15.651 16.6751 16.4234 15.9522 16.9695 15.0675C17.5156 14.1828 17.8156 13.1683 17.8386 12.1289C17.8615 11.0894 17.6065 10.0627 17.1 9.15471C16.5934 8.24675 15.8537 7.49049 14.9571 6.96403C14.3725 6.62072 13.735 6.38326 13.0739 6.25958C12.4876 6.1499 12 5.67647 12 5.08Z"
			fill="currentColor"
		/>
	</svg>
)
