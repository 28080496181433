import { StoryblokLanguage } from 'service'

export enum CountryCode {
	Austria = 'sportsaustria',
	Netherlands = 'nl',
	Kosovo = 'xk',
}

export enum Language {
	Austria = 'de-AT',
	AustriaEn = 'en-AT',
	Netherlands = 'nl-NL',
	NetherlandsEn = 'en-NL',
	Kosovo = 'sq-XK',
	KosovoEn = 'en-XK',
}

export type DomainLocales =
	| Language.Austria
	| Language.Netherlands
	| Language.Kosovo

export type EnglishLocales =
	| Language.AustriaEn
	| Language.NetherlandsEn
	| Language.KosovoEn

enum CountryName {
	Austria = 'Österreich',
	Netherlands = 'Nederland',
	Kosovo = 'Kosovo',
}

export enum CountryNameEn {
	Austria = 'Austria',
	Netherlands = 'Netherlands',
	Kosovo = 'Kosovo',
}

type Lookups =
	| {
			language: Language
			storyblokLanguage?: never
			countryCode?: never
	  }
	| {
			language?: never
			storyblokLanguage: StoryblokLanguage
			countryCode?: never
	  }
	| {
			language?: never
			storyblokLanguage?: never
			countryCode: CountryCode
	  }

type LookupsReturn = {
	countryCode: CountryCode
	countryName: CountryName
	countryNameEn: CountryNameEn
	language: DomainLocales
	englishLanguage: EnglishLocales
	// languageName: LanguageName
	// languageNameEn: LanguageNameEn
	storyblokLanguage: StoryblokLanguage
	// languageCode: LanguageCode
}

export const lookups = ({
	language,
	storyblokLanguage,
	countryCode,
}: Lookups): LookupsReturn => {
	const property = language || storyblokLanguage || countryCode

	switch (property) {
		case CountryCode.Austria:
		case Language.Austria:
		case Language.AustriaEn:
		case StoryblokLanguage.Austria: {
			return {
				countryCode: CountryCode.Austria,
				countryName: 'Sports Austria' as CountryName,
				countryNameEn: 'Sports Austria' as CountryNameEn,
				language: Language.Austria as DomainLocales,
				englishLanguage: Language.AustriaEn as EnglishLocales,
				storyblokLanguage: StoryblokLanguage.Austria,
			}
		}
		case CountryCode.Netherlands:
		case Language.Netherlands:
		case Language.NetherlandsEn:
		case StoryblokLanguage.Dutch: {
			return {
				countryCode: CountryCode.Netherlands,
				countryName: CountryName.Netherlands,
				countryNameEn: CountryNameEn.Netherlands,
				language: Language.Netherlands as DomainLocales,
				englishLanguage: Language.NetherlandsEn as EnglishLocales,
				storyblokLanguage: StoryblokLanguage.Dutch,
			}
		}
		case CountryCode.Kosovo:
		case Language.Kosovo:
		case Language.KosovoEn:
		case StoryblokLanguage.Albanian: {
			return {
				countryCode: CountryCode.Kosovo,
				countryName: CountryName.Kosovo,
				countryNameEn: CountryNameEn.Kosovo,
				language: Language.Kosovo as DomainLocales,
				englishLanguage: Language.KosovoEn as EnglishLocales,
				storyblokLanguage: StoryblokLanguage.Albanian,
			}
		}
		default: {
			return {
				countryCode: '' as CountryCode,
				countryName: '' as CountryName,
				countryNameEn: '' as CountryNameEn,
				language: '' as DomainLocales,
				englishLanguage: '' as EnglishLocales,
				storyblokLanguage: '' as StoryblokLanguage,
			}
		}
	}
}
