import { useCallback, useRef, useState } from 'react'
import {
	DayPickerSingleProps,
	SelectSingleEventHandler,
} from 'react-day-picker'
import { format } from 'date-fns'
import clsx from 'clsx'
import { FieldHint, Input, Calendar } from 'ui'
import CustomDayPicker, { DynamicCustomDayPicker } from './CustomDayPicker'
import { useOutsideClick, useKeyboard, useLazyPreload, toCET } from 'common'

export interface DatePickerProps
	extends Omit<DayPickerSingleProps, 'selected' | 'onSelect' | 'mode'> {
	locale: Locale
	value?: string
	label?: string
	hint?: string
	placeholder?: string
	wrapperClass?: string
	isInvalid?: boolean
	defaultOpened?: boolean
	disabledInput?: boolean
	errorText?: string
	onSelect: SelectSingleEventHandler
	forceOpened?: boolean
	inputClass?: string
	mode?: 'single' | 'range'
}

export const DatePicker = ({
	value,
	label,
	hint,
	placeholder,
	wrapperClass,
	inputClass,
	isInvalid,
	onSelect,
	defaultOpened,
	errorText,
	disabledInput,
	forceOpened,
	mode,
	...props
}: DatePickerProps) => {
	const inputRef = useRef<HTMLInputElement>(null)
	const [showPicker, setShowPicker] = useState(defaultOpened)

	const handleShowPicker = useCallback(() => {
		setShowPicker(true)
	}, [])

	const handleDateSelect: SelectSingleEventHandler = useCallback(
		(...args) => {
			setShowPicker(false)
			onSelect(...args)
		},
		[onSelect]
	)

	useKeyboard('Enter', handleShowPicker, inputRef.current as HTMLInputElement)

	const dateValue = value ? toCET(new Date(value)) : undefined
	const formattedDate = dateValue ? format(dateValue, 'dd/MM/yyyy') : ''

	useLazyPreload(DynamicCustomDayPicker, false)

	const calendarRef = useOutsideClick<HTMLDivElement>(() => {
		setShowPicker(false)
	})

	return (
		<div className={clsx(wrapperClass && wrapperClass)}>
			{showPicker || forceOpened ? (
				<div ref={calendarRef}>
					<CustomDayPicker
						selected={dateValue}
						onSelect={handleDateSelect}
						mode={mode || 'single'}
						{...props}
					/>
					{errorText && <FieldHint text={errorText} isInvalid={isInvalid} />}
				</div>
			) : (
				<Input
					ref={inputRef}
					readOnly
					value={formattedDate}
					onClick={handleShowPicker}
					className={clsx(
						'text-primary disabled:text-gray-25 placeholder:text-primary cursor-pointer',
						inputClass
					)}
					placeholder={placeholder}
					hint={hint}
					label={label}
					suffix={
						<Calendar
							className={clsx(
								!isInvalid && !disabledInput && 'text-primary',
								isInvalid && !disabledInput && 'text-status-error',
								disabledInput && 'text-gray-25'
							)}
						/>
					}
					isInvalid={isInvalid}
					disabled={disabledInput}
				/>
			)}
		</div>
	)
}
