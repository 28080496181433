import { ComponentPropsWithoutRef, ReactNode, forwardRef, useRef } from 'react'
import { motion, useScroll, useTransform, interpolate } from 'framer-motion'
import clsx from 'clsx'

interface Scrollable extends ComponentPropsWithoutRef<'div'> {
	children: ReactNode
}

const shadowClassName =
	'z-default pointer-events-none absolute left-0 w-full from-white'

export const Scrollable = forwardRef<HTMLDivElement, Scrollable>(
	({ className, children }, ref) => {
		const scrollableRef = useRef<HTMLDivElement>(null)

		const { scrollYProgress, scrollY } = useScroll({
			container: scrollableRef,
		})

		const topShadowHeight = useTransform(scrollYProgress, [0, 0.5], [16, 40], {
			mixer: (from, to) => (value) => {
				if (scrollY.get() === 0) {
					return from
				}

				return interpolate([0, 1], [from, to])(value)
			},
		})
		const bottomShadowHeight = useTransform(scrollYProgress, [0.5, 1], [40, 16])

		return (
			<div
				ref={ref}
				className="relative flex min-h-0 w-full shrink grow basis-auto overflow-hidden"
			>
				<motion.span
					style={{ height: topShadowHeight }}
					className={clsx(shadowClassName, '-top-[1px] bg-gradient-to-b')}
				/>
				<motion.span
					style={{ height: bottomShadowHeight }}
					className={clsx(shadowClassName, '-bottom-[1px] bg-gradient-to-t')}
				/>
				<div
					ref={scrollableRef}
					className="flex shrink grow basis-auto flex-col overflow-y-auto overflow-x-hidden"
				>
					<div
						className={clsx(
							'sm:px-8 sm:pb-8 sm:pt-4 md:px-14 md:pb-12 md:pt-3 lg:px-14 lg:pb-12 lg:pt-3',
							className
						)}
					>
						{children}
					</div>
				</div>
			</div>
		)
	}
)
