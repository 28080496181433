import { ComponentPropsWithoutRef } from 'react'

export const UploadIcon = (props: ComponentPropsWithoutRef<'svg'>) => {
	return (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M18.25 14C18.6656 14 19 14.3344 19 14.75V17C19 18.6562 17.6562 20 16 20H8C6.34312 20 5 18.6562 5 17V14.75C5 14.3344 5.33562 14 5.75 14C6.16406 14 6.5 14.3344 6.5 14.75V17C6.5 17.825 7.175 18.5 8 18.5H16C16.825 18.5 17.5 17.825 17.5 17V14.75C17.5 14.3344 17.8344 14 18.25 14ZM12.0156 4C12.2312 4 12.4344 4.09181 12.575 4.25194L16.7344 9.01562C16.9094 9.20937 16.9719 9.45937 16.9719 9.70937C16.9719 9.85625 16.9688 10.0063 16.9062 10.1438C16.7406 10.5125 16.375 10.75 15.9781 10.75H14.2219V14.5C14.2219 15.1906 13.6875 15.75 12.9719 15.75H10.9719C10.3094 15.75 9.72188 15.1906 9.72188 14.5V10.75H8.02094C7.6225 10.75 7.25844 10.5125 7.09219 10.1438C6.92344 9.76562 6.98969 9.32188 7.26312 9.01562L11.4563 4.25194C11.5969 4.09178 11.8 4 12.0156 4ZM12.0156 5.88L9.03438 9.25H11.2219V14.25H12.7219V9.25H14.9375L12.0156 5.88Z"
				fill="currentColor"
			/>
		</svg>
	)
}
