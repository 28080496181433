import { ComponentPropsWithoutRef } from 'react'

export function ExternalLink(props: ComponentPropsWithoutRef<'svg'>) {
	return (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M13.1429 5.14286C13.1429 4.51179 13.6536 4 14.2857 4H18.825C19.0107 4 19.1607 4.03071 19.2929 4.08679C19.3964 4.14214 19.5536 4.22393 19.6643 4.33214C19.6643 4.33393 19.6643 4.33571 19.6679 4.33714C19.8893 4.55929 19.9679 4.84929 20 5.13929C20 5.14071 20 5.14179 20 5.14286V9.71429C20 10.3464 19.4893 10.8571 18.8571 10.8571C18.225 10.8571 17.7143 10.3464 17.7143 9.71429V7.90357L11.6643 13.95C11.2179 14.3964 10.4964 14.3964 10.05 13.95C9.60357 13.5036 9.60357 12.7821 10.05 12.3357L16.0964 6.28571H14.2857C13.6536 6.28571 13.1429 5.77393 13.1429 5.14286ZM4 7.42857C4 6.16607 5.02321 5.14286 6.28571 5.14286H9.71429C10.3464 5.14286 10.8571 5.65464 10.8571 6.28571C10.8571 6.91786 10.3464 7.42857 9.71429 7.42857H6.28571V17.7143H16.5714V14.2857C16.5714 13.6536 17.0821 13.1429 17.7143 13.1429C18.3464 13.1429 18.8571 13.6536 18.8571 14.2857V17.7143C18.8571 18.975 17.8321 20 16.5714 20H6.28571C5.02321 20 4 18.975 4 17.7143V7.42857Z"
				fill="currentColor"
			/>
		</svg>
	)
}
