import { ComponentPropsWithoutRef } from 'react'

export const Plus = (props: ComponentPropsWithoutRef<'svg'>) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			fill="none"
			{...props}
		>
			<path
				fill="currentColor"
				d="M18.5 12a1 1 0 0 1-1 1H13v4.5a1 1 0 1 1-2 0V13H6.5a1 1 0 1 1 0-2H11V6.5a1 1 0 1 1 2 0V11h4.5c.553 0 1 .447 1 1Z"
			/>
		</svg>
	)
}
