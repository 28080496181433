import { Controller, useFormContext } from 'react-hook-form'
import { Input, type InputProps } from '../'

interface ControlledInputProps extends InputProps {
	name: string
}

export const ControlledInput = ({
	name,
	hint,
	defaultValue = '',
	...props
}: ControlledInputProps) => {
	const { control } = useFormContext()

	return (
		<Controller
			name={name}
			control={control}
			defaultValue={defaultValue}
			render={({ fieldState: { error }, field }) => (
				<Input
					id={name}
					isInvalid={!!error}
					hint={error?.message || hint}
					{...props}
					{...field}
				/>
			)}
		/>
	)
}
