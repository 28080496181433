import { CSSProperties } from 'react'
import { z } from 'zod'
import clsx from 'clsx'
import {
	Container,
	Button,
	Noop,
	ButtonTheme,
	AnimationBlock,
	OptimizedImage,
	ReadMoreContent,
	VideoCard,
	ExternalLink,
} from 'ui'
import { StoryblokImageGallerySchema } from './StoryblokImageGallery'
import { useTranslation } from 'next-i18next'

type SBImageGallery = z.TypeOf<typeof StoryblokImageGallerySchema>

const protocolRegex = new RegExp(/^(https:\/\/|http:\/\/)/)

type ImageGalleryProps = {
	tag?: SBImageGallery['tag']
	title: SBImageGallery['title']
	subtitle?: SBImageGallery['subtitle']
	secondaryText: string
	primaryButtonText?: SBImageGallery['primary_button_text']
	primaryButtonUrl?: SBImageGallery['primary_button_url']
	secondaryButtonText?: SBImageGallery['secondary_button_text']
	secondaryButtonUrl?: SBImageGallery['secondary_button_url']
	videoId?: SBImageGallery['video_id']
	videoTitle?: SBImageGallery['video_title']
	scrollId?: SBImageGallery['scroll_id']
	image?: string | null
	hideShowMoreButton?: SBImageGallery['hide_show_more_button']
	imagePosition?: SBImageGallery['image_position']
	theme?: SBImageGallery['theme']
	parent?: string
}

const ImageGallery = ({
	title,
	tag,
	subtitle,
	secondaryText,
	image,
	videoId,
	videoTitle,
	scrollId,
	imagePosition,
	hideShowMoreButton,
	primaryButtonText,
	primaryButtonUrl,
	secondaryButtonText,
	secondaryButtonUrl,
	parent,
	...props
}: ImageGalleryProps) => {
	const { t } = useTranslation()

	const isCarousel = parent === 'carousel'

	const theme = props.theme || 'default'

	const generateImageSection = () => {
		if (!image && !videoId) {
			return <Noop />
		}

		if (videoId) {
			return (
				<VideoCard
					videoId={videoId}
					title={videoTitle}
					image={image}
					isImageGallery
				/>
			)
		}

		if (image) {
			return (
				<div className="relative pt-[100%]">
					<OptimizedImage
						alt={title || 'Image gallery'}
						src={image}
						className="object-cover"
						fill
						sizes="(max-width: 819px) 100vw, 50vw"
					/>
				</div>
			)
		}
	}

	const generateButtons = () => {
		if (!primaryButtonText && !primaryButtonUrl) {
			return null
		}

		let buttonTheme: ButtonTheme
		if (theme !== 'default') {
			buttonTheme = 'light'
		} else {
			buttonTheme = 'colored'
		}

		const isPrimaryExternalLink = protocolRegex.test(primaryButtonUrl || '')
		const isSecondaryExternalLink = protocolRegex.test(secondaryButtonUrl || '')

		return (
			<div
				className={clsx(
					'mt-auto flex flex-wrap gap-2 sm:mt-4 sm:flex-col md:flex-col'
				)}
			>
				{!!secondaryButtonText && (
					<Button
						href={secondaryButtonUrl}
						variant="secondary"
						theme={buttonTheme}
						iconPosition="right"
						{...(isSecondaryExternalLink && {
							icon: <ExternalLink />,
						})}
					>
						{secondaryButtonText}
					</Button>
				)}
				{!!primaryButtonText && (
					<Button
						href={primaryButtonUrl}
						theme={buttonTheme}
						iconPosition="right"
						{...(isPrimaryExternalLink && {
							icon: <ExternalLink />,
						})}
					>
						{primaryButtonText}
					</Button>
				)}
			</div>
		)
	}

	return (
		<AnimationBlock
			className="section h-full"
			enabled={!isCarousel}
			{...(scrollId && { id: scrollId })}
		>
			<Container
				className="h-full"
				{...(theme !== 'default' && {
					style: {
						'--primary': `var(--${theme})`,
					} as CSSProperties,
				})}
			>
				<div
					className={clsx(
						'flex h-full items-center justify-center rounded-sm sm:flex-col',
						!isCarousel && 'gap-6 !bg-transparent sm:gap-0',
						theme !== 'default' ? 'bg-primary text-white' : 'bg-white',
						imagePosition === '2' && 'flex-row-reverse'
					)}
				>
					<div className="relative h-auto sm:w-full md:w-1/2 lg:w-1/2">
						{generateImageSection()}
					</div>
					<div
						className={clsx(
							'flex h-full flex-col sm:w-full sm:p-6 md:w-1/2 md:p-8 lg:w-1/2 lg:p-12',
							!generateButtons() && 'md:justify-center lg:justify-center',
							!isCarousel &&
								clsx(
									'rounded-sm',
									theme !== 'default' ? 'bg-primary text-white' : 'bg-white'
								)
						)}
					>
						<h3>
							{tag && (
								<span
									className={clsx(
										'subtitle_highlight block',
										theme === 'default' ? 'text-primary' : 'text-white'
									)}
								>
									{tag}
								</span>
							)}
							{title && <span className="block">{title}</span>}
							{subtitle && (
								<span className="block text-base font-normal tracking-normal">
									{subtitle}
								</span>
							)}
						</h3>
						<ReadMoreContent
							hideShowMoreButton={hideShowMoreButton}
							className="last:mb-0 sm:my-2 md:my-4 lg:my-6"
							numberOfLines={5}
							modalProps={{
								title,
								description: secondaryText,
								primaryButtonText,
								primaryButtonUrl,
								secondaryButtonText,
								secondaryButtonUrl,
							}}
							t={t}
						>
							{secondaryText}
						</ReadMoreContent>
						{generateButtons()}
					</div>
				</div>
			</Container>
		</AnimationBlock>
	)
}

export default ImageGallery
