import { ComponentPropsWithoutRef } from 'react'
import Image from 'next/image'
import clsx from 'clsx'
import { AbstractButton } from 'ui'

interface TeamCardProps extends ComponentPropsWithoutRef<'div'> {
	image: string
	name: string
	position: string
	phone?: string
	mobilePhone?: string
	email?: string
	t: (key: string) => string
}

export function TeamCard({
	image,
	name,
	position,
	phone,
	mobilePhone,
	email,
	t,
	className,
}: TeamCardProps) {
	return (
		<div
			className={clsx('h-full overflow-hidden rounded-lg bg-white', className)}
		>
			<div className="relative pt-[100%]">
				<Image src={image} alt={name} fill />
			</div>
			<div className="p-6">
				<div className="mb-4 text-center">
					<span className="p_highlight block">{name}</span>
					<span className="text-gray-40">{position}</span>
				</div>
				{(phone || mobilePhone || email) && (
					<div className="text-primary flex flex-col items-center">
						{phone && (
							<AbstractButton href={`tel:${phone}`}>
								{t('telephoneShortLabel')}: {phone}
							</AbstractButton>
						)}
						{mobilePhone && (
							<AbstractButton href={`tel:${mobilePhone}`}>
								{t('mobilePhoneShortLabel')}: {mobilePhone}
							</AbstractButton>
						)}
						{email && (
							<AbstractButton href={`mailto:${email}`}>{email}</AbstractButton>
						)}
					</div>
				)}
			</div>
		</div>
	)
}
