import { ComponentProps } from 'react'

export const XMark = (props: ComponentProps<'svg'>) => (
	<svg viewBox="0 0 24 24" width="24" height="24" fill="none" {...props}>
		<path
			d="M16.7531 15.7231C17.0459 16.0151 17.0459 16.4889 16.7531 16.781C16.4603 17.073 15.9853 17.073 15.6925 16.781L12 13.0706L8.28124 16.7797C7.98846 17.0717 7.51343 17.0717 7.22062 16.7797C6.9278 16.4877 6.92784 16.0139 7.22062 15.7219L10.9406 12.014L7.21959 8.27689C6.9268 7.98487 6.9268 7.51108 7.21959 7.21903C7.51237 6.92698 7.9874 6.92701 8.28021 7.21903L12 10.9574L15.7187 7.24833C16.0115 6.95631 16.4866 6.95631 16.7794 7.24833C17.0722 7.54035 17.0721 8.01414 16.7794 8.30619L13.0594 12.014L16.7531 15.7231Z"
			fill="currentColor"
		/>
	</svg>
)
