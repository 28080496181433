import { createPortal } from 'react-dom'
import { AnimatePresence, Variants, motion } from 'framer-motion'
import { Button, ClientOnly } from 'ui'
import { useScrollLock, useOutsideClick } from 'common'
import { Backdrop } from '../Backdrop'
import { TRANSITION } from '../Overlays.data'
import YouTubeIframe from '../../../atoms/YouTubeIframe'

const variants: Variants = {
	show: {
		y: 0,
		opacity: 1,
		transition: TRANSITION,
	},
	hide: {
		y: 20,
		opacity: 0,
		transition: TRANSITION,
	},
}

type VideoModal = {
	videoId: string
	isOpened: boolean
	onClose: () => void
}

function VideoModal({ videoId, isOpened, onClose }: VideoModal) {
	const modalRef = useOutsideClick<HTMLDivElement>(onClose)

	useScrollLock(isOpened)

	const modal = (
		<Backdrop className="overflow-y-auto" isOpened>
			<motion.div
				ref={modalRef}
				variants={variants}
				animate="show"
				initial="hide"
				exit="hide"
				className="relative m-auto w-full max-w-[800px]"
			>
				<div className="relative pt-[56.25%]">
					<YouTubeIframe
						video_id={videoId}
						className="absolute inset-0 h-full"
					/>
				</div>
				<Button
					variant="secondary"
					theme="light"
					onClick={onClose}
					className="mx-auto mt-4"
				>
					Close Video
				</Button>
			</motion.div>
		</Backdrop>
	)

	return createPortal(modal, document.getElementById('modal') as HTMLElement)
}

export default function WithClientOnly(props: VideoModal) {
	return (
		<ClientOnly>
			<AnimatePresence>
				{props.isOpened && <VideoModal {...props} />}
			</AnimatePresence>
		</ClientOnly>
	)
}
