import {
	Control,
	Controller,
	FieldValues,
	useFormContext,
} from 'react-hook-form'
import { Checkbox, CheckboxProps } from '../Checkbox'

interface ControlledCheckboxProps<T extends FieldValues = any>
	extends CheckboxProps {
	name: string
	control?: Control<T>
}

export const ControlledCheckbox = ({
	name,
	control,
	...props
}: ControlledCheckboxProps) => {
	const { control: contextControl, getValues } = useFormContext() ?? {}

	return (
		<Controller
			name={name}
			control={control ?? contextControl}
			render={({ fieldState: { error }, field }) => (
				<Checkbox
					isInvalid={!!error}
					hint={error?.message}
					defaultChecked={getValues?.(name)}
					{...props}
					{...field}
				/>
			)}
		/>
	)
}
