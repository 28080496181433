import { useEffect, useRef } from 'react'
import { useIsomorphicLayoutEffect } from './useIsomorphicLayoutEffect'

export function useOutsideClick<TElement extends HTMLElement>(
	// eslint-disable-next-line @typescript-eslint/ban-types
	callback: Function,
	isPersistent?: boolean
) {
	const savedCallback = useRef(callback)
	const ref = useRef<TElement>(null)

	// Remember the latest callback if it changes.
	useIsomorphicLayoutEffect(() => {
		savedCallback.current = callback
	}, [callback])

	useEffect(() => {
		const handleClick = (event: any) => {
			if (ref.current && !ref.current.contains(event.target) && !isPersistent) {
				savedCallback.current()
			}
		}

		const handleKeydown = (event: KeyboardEvent) => {
			if (event.key === 'Escape' && !isPersistent) {
				savedCallback.current()
			}
		}

		document.addEventListener('mousedown', handleClick)
		document.addEventListener('keydown', handleKeydown)

		return () => {
			document.removeEventListener('mousedown', handleClick)
			document.removeEventListener('keydown', handleKeydown)
		}
	}, [isPersistent])

	return ref
}
