import { FormItem, RichTextResolverService } from 'service'

export const getDescriptiveKeyData = (
	data: Record<string, any>,
	form: Array<FormItem>
) => {
	const descriptiveKeyData: Record<string, any> = {}
	for (const [key, value] of Object.entries(data)) {
		const formItem = form.find(({ label, api_field_name }) => {
			const labelCamelCase = new RichTextResolverService(
				label
			).resolveToCamelCase()
			api_field_name ||= labelCamelCase
			return key === api_field_name
		})

		if (formItem) {
			const labelString = new RichTextResolverService(
				formItem.label
			).resolveToString()
			descriptiveKeyData[labelString] = value
		} else {
			descriptiveKeyData[key] = value
		}
	}

	return descriptiveKeyData
}
