import { useEffect, useState } from 'react'

/**
 * @description a hook for using media queries
 * @param query query string that we want to check
 * @param defaultState fallback if not a browser
 */
export function useMedia(query: string, defaultState = false): boolean {
	const [state, setState] = useState(defaultState)

	useEffect(() => {
		let isMounted = true
		const mediaQueryList = window.matchMedia(query)

		const handleChange = (): void => {
			if (!isMounted) {
				return
			}
			setState(!!mediaQueryList.matches)
		}

		try {
			mediaQueryList.addEventListener('change', handleChange)
		} catch (e) {
			mediaQueryList.addListener(handleChange)
		}

		setState(mediaQueryList.matches)

		return () => {
			isMounted = false

			try {
				mediaQueryList.removeEventListener('change', handleChange)
			} catch (e) {
				mediaQueryList.addListener(handleChange)
			}
		}
	}, [query])

	return state
}
