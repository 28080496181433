import { ReactElement } from 'react'
import { AnimatePresence, motion, Variants } from 'framer-motion'
import clsx from 'clsx'
import { useToast, ToastType, useMedia, MEDIA } from 'common'
import { AbstractButton, XMark } from 'ui'

const stripeStyles =
	'before:absolute before:w-2 before:h-full before:bg-current before:top-0 before:left-0'

const colorLookup = new Map<ToastType, string>([
	['success', 'text-status-correct'],
	['error', 'text-status-error'],
	['warning', 'text-status-warning'],
	['info', 'text-accent-blue'],
])

export const Toast = (): ReactElement => {
	const isXsMobile = useMedia(MEDIA.xsMobile)
	const {
		toasts,
		handleRemove,
		handleMouseEnter,
		handleMouseLeave,
		handleDragEnd,
	} = useToast()

	const variants: Record<'parent' | 'child', Variants> = {
		parent: {
			exit: (_a, initial) => {
				const slideLeft = Number(initial.x) < 0 && isXsMobile

				return {
					x: slideLeft ? '-100%' : '100%',
					opacity: 0,
				}
			},
		},
		child: {
			initial: {
				y: isXsMobile ? '200%' : '-200%',
			},
			animate: {
				y: 0,
			},
		},
	}

	return (
		<div className="z-toast xs:top-auto xs:bottom-6 xs:right-6 xs:left-6 xs:max-w-[calc(100%_-_3rem)] fixed right-4 top-4 flex w-full max-w-xs flex-col">
			<AnimatePresence>
				{toasts.map(({ id, type, title, description }) => (
					<motion.div
						key={id}
						className="mb-4 w-full last:mb-0"
						layout
						variants={variants.parent}
						exit="exit"
						drag="x"
						dragConstraints={{ left: 0, right: 0 }}
						onDragEnd={(_event, panInfo) => handleDragEnd(id, panInfo)}
						onMouseEnter={() => handleMouseEnter(id)}
						onMouseLeave={() => handleMouseLeave(id)}
					>
						<motion.div
							className={clsx(
								'shadow-default relative bg-white py-2 pl-6 pr-12',
								colorLookup.get(type),
								stripeStyles
							)}
							variants={variants.child}
							layout
							animate="animate"
							initial="initial"
						>
							<AbstractButton
								onClick={() => handleRemove(id)}
								aria-label="Close notification"
								className="absolute right-0 top-0 p-2 text-black transition-opacity hover:opacity-70"
							>
								<XMark />
							</AbstractButton>
							<h4 className="p_highlight">{title}</h4>
							{description && <p>{description}</p>}
						</motion.div>
					</motion.div>
				))}
			</AnimatePresence>
		</div>
	)
}
