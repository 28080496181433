import { z } from 'zod'
import { RichTextResolverService } from 'service'
import { Container } from '../../atoms'
import { RichtextContentSchema } from './RichtextContent.schema'

type RichtextContentProps = {
	blok: z.TypeOf<typeof RichtextContentSchema>
}

export const RichtextContent = ({ blok }: RichtextContentProps) => {
	const content = new RichTextResolverService(blok.content).baseResolve()

	return (
		<div className="section">
			<Container id={blok.scroll_id}>
				<div>{content}</div>
			</Container>
		</div>
	)
}
