import { useEffect, useState } from 'react'
import { useIsomorphicLayoutEffect } from './useIsomorphicLayoutEffect'
import { getScrollbarWidth } from '../utils'

type ReturnTuple = [boolean, (_lock: boolean) => void]

/**
 * @description a hook for locking background scroll
 * @param initialLock an initial state
 * @returns a tuple with lock state and the setLock method
 * @example useScrollLock(true);
 * const [lock, setLock] = useScrollLock(false);
 */
export function useScrollLock(initialLock = false): ReturnTuple {
	const [lock, setLock] = useState(initialLock)

	useIsomorphicLayoutEffect(() => {
		if (!lock) {
			return
		}

		const originalOverflow = document.body.style.overflow
		const originalPaddingRight = document.body.style.paddingRight

		document.body.style.overflow = 'hidden'

		const scrollBarWidth = getScrollbarWidth()

		if (scrollBarWidth) {
			document.body.style.paddingRight = `${scrollBarWidth}px`
		}

		return () => {
			document.body.style.overflow =
				originalOverflow === 'hidden' ? '' : originalOverflow

			if (scrollBarWidth) {
				document.body.style.paddingRight = originalPaddingRight
			}
		}
	}, [lock])

	useEffect(() => {
		if (lock !== initialLock) {
			setLock(initialLock)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [initialLock])

	return [lock, setLock]
}
