import { ComponentPropsWithoutRef } from 'react'

export const CirclePlus = (props: ComponentPropsWithoutRef<'svg'>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="48"
		height="48"
		fill="none"
		{...props}
	>
		<path
			fill="currentColor"
			d="M30.5 24a1 1 0 0 1-1 1H25v4.5a1 1 0 1 1-2 0V25h-4.5a1 1 0 1 1 0-2H23v-4.5a1 1 0 1 1 2 0V23h4.5c.553 0 1 .447 1 1Z"
		/>
		<rect
			width="46"
			height="46"
			x="1"
			y="1"
			stroke="currentColor"
			strokeWidth="2"
			rx="23"
		/>
	</svg>
)
