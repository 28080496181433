import { ComponentPropsWithoutRef } from 'react'

export const ArrowRight = (props: ComponentPropsWithoutRef<'svg'>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="24"
		height="24"
		fill="none"
		{...props}
	>
		<path
			fill="currentColor"
			d="m13.269 6.207 5.5 5.25a.75.75 0 0 1 0 1.086l-5.5 5.25a.751.751 0 0 1-1.036-1.086l4.147-3.956H5.75a.75.75 0 0 1 0-1.5h10.628l-4.146-3.956a.753.753 0 0 1-.026-1.063c.288-.3.735-.31 1.063-.025Z"
		/>
	</svg>
)
