import { ComponentPropsWithoutRef, ReactNode, useRef, useState } from 'react'
import { AnimatePresence } from 'framer-motion'
import clsx from 'clsx'
import { useIsomorphicLayoutEffect, useModal } from 'common'
import { AbstractButton } from '../../atoms'
import { Modal } from '../Overlays'

const LINE_HEIGHT = 24
const OFFSET = 2

export type ModalProps = {
	tag?: string
	title: string
	description: ReactNode
	primaryButtonText?: string
	primaryButtonUrl?: string | null
	primaryButtonOnClick?: () => void
	secondaryButtonText?: string
	secondaryButtonUrl?: string
	secondaryButtonOnClick?: () => void
}

interface ReadMoreContentProps extends ComponentPropsWithoutRef<'div'> {
	modalProps: ModalProps
	t: (key: string) => string
	hideShowMoreButton?: boolean
	lineHeight?: number
	numberOfLines?: 2 | 5 | 7 // If needed different number, make sure to add it to safelist of tailwind.config.js
}

export function ReadMoreContent({
	hideShowMoreButton,
	modalProps,
	className,
	children,
	t,
	lineHeight = LINE_HEIGHT,
	numberOfLines = 5,
}: ReadMoreContentProps) {
	const maxHeight = lineHeight * numberOfLines + OFFSET

	const descriptionRef = useRef<HTMLDivElement>(null)
	const [showMore, setShowMore] = useState(false)
	const { modal, openModal, closeModal } = useModal<'read-more'>()

	useIsomorphicLayoutEffect(() => {
		const element = descriptionRef.current

		if (!element) {
			return
		}

		if (element.scrollHeight > maxHeight) {
			setShowMore(true)
		}
	}, [hideShowMoreButton, maxHeight])

	if (hideShowMoreButton) {
		return <div className={clsx(className)}>{children}</div>
	}

	return (
		<>
			<AnimatePresence>
				{modal === 'read-more' && (
					<Modal title={modalProps.tag} onClose={closeModal}>
						<Modal.Body>
							<h3 className="word_break">{modalProps.title}</h3>
							<div className="flex flex-wrap gap-4 whitespace-pre-line pt-4">
								{modalProps.description}
							</div>
						</Modal.Body>
						{(modalProps.secondaryButtonText ||
							modalProps.primaryButtonText) && (
							<Modal.Bottom>
								{modalProps.secondaryButtonText && (
									<Modal.SecondaryButton
										onClick={modalProps.secondaryButtonOnClick}
										href={modalProps.secondaryButtonUrl}
									>
										{modalProps.secondaryButtonText || ''}
									</Modal.SecondaryButton>
								)}
								{modalProps.primaryButtonText && (
									<Modal.PrimaryButton
										onClick={modalProps.primaryButtonOnClick}
										href={modalProps.primaryButtonUrl || ''}
									>
										{modalProps.primaryButtonText}
									</Modal.PrimaryButton>
								)}
							</Modal.Bottom>
						)}
					</Modal>
				)}
			</AnimatePresence>
			<div className={clsx(className)}>
				<div
					ref={descriptionRef}
					className={clsx(
						'inline-block w-full overflow-hidden',
						`line-clamp-${numberOfLines}`
					)}
					style={{
						maxHeight: `${maxHeight}px`,
					}}
				>
					{children}
				</div>
				{showMore && (
					<AbstractButton
						onClick={() => openModal('read-more')}
						className="p_highlight mt-2"
					>
						{t('Show more')}
					</AbstractButton>
				)}
			</div>
		</>
	)
}
