import { z } from 'zod'

const ColumnsSchema = z.enum(['full', '6', '4', '3', ''])

export const GridSchema = z.object({
	title: z.string().optional(),
	number_of_columns_desktop: ColumnsSchema.exclude(['']),
	number_of_columns_tablet: ColumnsSchema.optional(),
	number_of_columns_mobile: ColumnsSchema.optional(),
	children: z.array(z.any()),
	isNarrow: z.boolean().optional(),
	scroll_id: z.string().optional(),
})
