import { ComponentPropsWithoutRef } from 'react'
import Head from 'next/head'
import FocusTrap from 'focus-trap-react'
import clsx from 'clsx'

interface Backdrop extends ComponentPropsWithoutRef<'div'> {
	isOpened: boolean
	disableFocusTrap?: boolean
}

export function Backdrop({
	isOpened,
	disableFocusTrap,
	className,
	children,
}: Backdrop) {
	return (
		<FocusTrap
			active={disableFocusTrap ? false : isOpened}
			focusTrapOptions={{ initialFocus: false }}
		>
			<div
				className={clsx(
					isOpened &&
						clsx(
							'z-modal bg-black-alpha/[0.32] fixed inset-0 flex backdrop-blur md:px-6 md:py-14 lg:px-12 lg:py-14',
							className
						)
				)}
			>
				{isOpened && (
					<Head>
						{/* Needed for status bar in Safari */}
						<meta name="theme-color" content="#B5B5B5" />
					</Head>
				)}
				{children}
			</div>
		</FocusTrap>
	)
}
