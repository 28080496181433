import { ReactNode, useState } from 'react'
import { useOutsideClick } from 'common'
import { OptimizedImage, InfoTooltip } from 'ui'
import clsx from 'clsx'

type MultiUploadTooltipProps = {
	children: ReactNode
}

const MultiUploadTooltip = ({ children }: MultiUploadTooltipProps) => {
	const [tooltipIsVisible, setTooltipIsVisible] = useState(false)

	const handleInfoClick = () => {
		setTooltipIsVisible(!tooltipIsVisible)
	}

	const tooltipRef = useOutsideClick<HTMLDivElement>(() => {
		setTooltipIsVisible(false)
	})

	return (
		<div className="group relative">
			<span onClick={handleInfoClick}>
				<InfoTooltip />
			</span>
			<div
				ref={tooltipRef}
				className={clsx(
					'absolute right-8 top-[-34px] flex scale-0 group-hover:scale-100',
					tooltipIsVisible ? 'scale-100' : 'scale-0'
				)}
			>
				{children}
				<OptimizedImage
					src="/tooltip-arrow-right.svg"
					alt="Tooltip icon"
					width={12}
					height={24}
				/>
			</div>
		</div>
	)
}

export default MultiUploadTooltip
