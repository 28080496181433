import { useMemo } from 'react'
import { optimizeSBImage } from 'common'

type UseOptimizedImageProps = {
	src: string
	fill?: boolean
	width?: number
}

const DEVICE_SIZES = [640, 750, 828, 1080, 1200, 1920, 2048, 3840]

export function useOptimizedImage({
	src,
	fill,
	width,
}: UseOptimizedImageProps) {
	const optimizedSrc = optimizeSBImage(src, width)

	const srcSet = useMemo(() => {
		if (!fill) {
			const retinaSrc = optimizeSBImage(src, (width as number) * 2)

			return `${optimizedSrc} 1x, ${retinaSrc} 2x`
		}

		const prepareSrcSet = DEVICE_SIZES.map((size) => {
			const getSrc = optimizeSBImage(src, size)

			return `${getSrc} ${size}w`
		})

		return prepareSrcSet.join(', ')
	}, [fill, optimizedSrc, src, width])

	return {
		src: optimizedSrc,
		srcSet,
	}
}
