import { z } from 'zod'
import { PageSectionSchema } from './PageSection.schema'
import { Container } from 'ui'
import DynamicComponent from '@components/common/DynamicComponents/DynamicComponent'

type PageSectionProps = {
	blok: z.TypeOf<typeof PageSectionSchema>
}

const PageSection = ({ blok }: PageSectionProps) => {
	return (
		<div id={blok.scroll_id} className="pageSection">
			<Container>
				<h4 className="subtitle_highlight mb-2">{blok.title}</h4>
				{blok.body.map((item) => (
					<DynamicComponent key={item._uid} blok={item} />
				))}
			</Container>
		</div>
	)
}

export default PageSection
