import { ComponentPropsWithoutRef } from 'react'

export const Calendar = (props: ComponentPropsWithoutRef<'svg'>) => (
	<svg
		width="25"
		height="24"
		viewBox="0 0 25 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M12.5 11.25c.416 0 .75.334.75.75v1.5h1.5c.416 0 .75.334.75.75s-.334.75-.75.75h-1.5v1.5c0 .416-.334.75-.75.75a.748.748 0 0 1-.75-.75V15h-1.5a.748.748 0 0 1-.75-.75c0-.416.334-.75.75-.75h1.5V12c0-.416.334-.75.75-.75ZM10.25 6h4.5V4.75a.75.75 0 1 1 1.5 0V6h1.25a2 2 0 0 1 2 2v10c0 1.103-.897 2-2 2h-10a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h1.25V4.75a.75.75 0 1 1 1.5 0V6ZM7 18a.5.5 0 0 0 .5.5h10c.275 0 .5-.225.5-.5v-8H7v8Z"
			fill="currentColor"
		/>
	</svg>
)
