import clsx from 'clsx'
import { AbstractButton, Button, Container, Noop } from 'ui'
import { TextHeaderProps } from './TextHeader.data'

// TODO check later if it is better to replace all sectionHeaders with textHeader in the code
// It is working for now because when image_desktop doesn't exist sectionHeader renders textHeader

export const TextHeader = ({
	type = 'nested',
	tag,
	title,
	description,
	primaryButtonText,
	primaryButtonUrl,
	secondaryButtonText,
	secondaryButtonUrl,
	scrollId,
	className,
	cta,
	theme,
}: TextHeaderProps) => {
	const generateButtons = () => {
		if (!primaryButtonText && !secondaryButtonText) {
			return <Noop />
		}

		return (
			<div className="mt-6 flex flex-wrap gap-2 sm:w-full sm:flex-col">
				{secondaryButtonText && (
					<Button href={secondaryButtonUrl} variant="secondary">
						{secondaryButtonText}
					</Button>
				)}
				{primaryButtonText && (
					<Button href={primaryButtonUrl} theme={theme || 'colored'}>
						{primaryButtonText}
					</Button>
				)}
			</div>
		)
	}

	const isHeader = type === 'header'

	const getSpacing = () => {
		if (isHeader) {
			return 'sm:my-[72px] md:my-28 lg:my-[152px]'
		}

		if (type === 'standalone') {
			return 'section'
		}

		return 'sm:mb-6 md:mb-10 lg:mb-12'
	}

	return (
		<div className={getSpacing()} {...(scrollId && { id: scrollId })}>
			<Container isNarrow isNested={type === 'nested'}>
				<div
					className={clsx(
						'flex flex-col items-center justify-center',
						className
					)}
				>
					{isHeader ? (
						<h1 className="mb-2 text-center">
							{tag && (
								<span className="uppercase sm:text-2xl md:text-[32px] md:leading-10 lg:text-[32px] lg:leading-10">
									{tag}
								</span>
							)}
							<span className="super_title block">{title}</span>
						</h1>
					) : (
						<h2 className="mb-2 text-center normal-case">
							{tag && (
								<span className="block text-center uppercase">{tag}</span>
							)}
							<span className="super_title text-center">{title}</span>
						</h2>
					)}
					{description && (
						<span
							className={clsx(
								'text-center',
								isHeader && 'p_highlight text-gray-40'
							)}
						>
							{description}
						</span>
					)}
					{cta && (
						<AbstractButton
							href={cta.href}
							className="subtitle text-primary underline underline-offset-4"
						>
							{cta.label}
						</AbstractButton>
					)}
					{generateButtons()}
				</div>
			</Container>
		</div>
	)
}
