import { PropsWithChildren, useCallback, useRef, useState } from 'react'
import { PanInfo, useAnimation } from 'framer-motion'
import { createStore } from 'common'
import { Theme } from '../../atoms/Dots'

type CSSUnits = 'px' | 'rem'
type GapType = `${number}${CSSUnits}`

export type Config = {
	theme?: Theme
	gap?: GapType | null
	perView?: number
	isFullWidth?: boolean
}

export type ProviderProps = PropsWithChildren<{
	count: number
	config?: Config
	initialActiveSlide?: number
	onSlideChange?: (currentSlide: number) => void
}>

const DEFAULT_CONFIG = {
	gap: null,
	perView: 1,
	isFullWidth: false,
	theme: 'default',
} as const

export const [SliderStoreProvider, useSliderStore] = createStore(
	'SliderStore',
	({
		count,
		initialActiveSlide = 1,
		config = DEFAULT_CONFIG,
		onSlideChange,
	}: ProviderProps) => {
		const [currentSlide, setCurrentSlide] = useState(initialActiveSlide)
		const onSlideChangeRef = useRef(onSlideChange)

		const mergeConfigs = {
			...DEFAULT_CONFIG,
			...config,
		}

		const { perView } = mergeConfigs

		const controls = useAnimation()

		const lastSlide = Math.ceil(count - perView + 1)
		const firstSlide = 1

		const getPercent = useCallback(
			(slide = initialActiveSlide) => {
				const percent = 100 / perView

				const isRoundedPerView = Math.floor(perView) === perView
				const isLast = lastSlide === slide

				if (isLast && !isRoundedPerView) {
					// If perView is not rounded we want last slide to be aligned to the right
					return `${(slide - 1) * -percent + (perView - 1) * percent}%`
				}

				return `${(slide - 1) * -percent}%`
			},
			[initialActiveSlide, lastSlide, perView]
		)

		const triggerAnimation = useCallback(
			async (slide = currentSlide) => {
				const percent = getPercent(slide)

				onSlideChangeRef?.current && onSlideChangeRef.current(slide)

				controls.start({
					x: percent,
				})
			},
			[currentSlide, getPercent, controls]
		)

		const handleNext = useCallback(() => {
			setCurrentSlide((prev) => {
				const slide = prev + 1

				if (slide > lastSlide) {
					triggerAnimation(firstSlide)
					return firstSlide
				}

				triggerAnimation(slide)

				return slide
			})
		}, [lastSlide, triggerAnimation])

		const handlePrevious = useCallback(() => {
			setCurrentSlide((prev) => {
				const slide = prev - 1

				if (slide < 1) {
					triggerAnimation(lastSlide)
					return lastSlide
				}

				triggerAnimation(slide)

				return slide
			})
		}, [lastSlide, triggerAnimation])

		const handleGoTo = useCallback(
			(slide: number) => {
				setCurrentSlide(slide)
				triggerAnimation(slide)
			},
			[triggerAnimation]
		)

		const handleDragEnd = useCallback(
			(_event: any, { offset, velocity }: PanInfo) => {
				const absOffset = Math.abs(offset.x)
				const absVelocity = Math.abs(velocity.x)

				const direction = offset.x > 0 ? 'right' : 'left'

				if (absOffset < 100 && absVelocity < 200) {
					triggerAnimation()

					return
				}

				if (direction === 'left') {
					handleNext()
				} else {
					handlePrevious()
				}
			},
			[handleNext, handlePrevious, triggerAnimation]
		)

		return {
			count,
			currentSlide,
			controls,
			handleNext,
			handlePrevious,
			handleGoTo,
			handleDragEnd,
			getPercent,
			config: mergeConfigs,
		}
	}
)
