import { ReactNode, useCallback, useEffect, useRef, useState } from 'react'
import { PanInfo } from 'framer-motion'
import { v4 as uuidv4 } from 'uuid'
import { createStore, titleize, MEDIA, Timer } from '../utils'
import { useMedia } from './useMedia'

export type ToastType = 'success' | 'info' | 'warning' | 'error'

interface ToastConfig {
	title?: string
	description?: ReactNode
	duration?: number
}

interface Toast extends ToastConfig {
	type: ToastType
	id: string
}

const DELAY = 5_000

export const [ToastStoreProvider, useToast] = createStore('ToastStore', () => {
	const [toasts, setToasts] = useState<Array<Toast>>([])
	const timerRef = useRef<Record<string, Timer>>()
	const isXsMobile = useMedia(MEDIA.xsMobile)

	const removeById = useCallback(
		(id: string) =>
			setToasts((state) => state.filter(({ id: _id }) => _id !== id)),
		[]
	)

	const handleRemove = useCallback(
		(id: string) => {
			const currentTimer = timerRef.current?.[id]
			currentTimer?.clearTimeout()

			removeById(id)
		},
		[removeById]
	)

	const handleMouseEnter = useCallback((id: string) => {
		const currentTimer = timerRef.current?.[id]
		currentTimer?.pause()
	}, [])

	const handleMouseLeave = useCallback((id: string) => {
		const currentTimer = timerRef.current?.[id]
		currentTimer?.resume()
	}, [])

	const handleDragEnd = useCallback(
		(id: string, { offset, velocity }: PanInfo) => {
			let lengthX = offset.x
			let velocityX = velocity.x

			if (isXsMobile) {
				lengthX = Math.abs(offset.x)
				velocityX = Math.abs(velocity.x)
			}

			const isSwipedEnough = lengthX > 200 || velocityX > 100

			if (isSwipedEnough) {
				removeById(id)
			}
		},
		[removeById, isXsMobile]
	)

	const dispatch = useCallback(
		(
			type: ToastType,
			{ title, description, duration = DELAY }: ToastConfig = {}
		) => {
			const id = uuidv4()

			const generateToast: Toast = {
				title: title ?? titleize(type),
				id,
				type,
				description,
			}

			setToasts((state) => {
				if (isXsMobile) {
					return [...state, generateToast]
				}

				return [generateToast, ...state]
			})

			const timer = new Timer(() => {
				removeById(id)
			}, duration)

			timerRef.current = {
				...timerRef.current,
				[id]: timer,
			}
		},
		[isXsMobile, removeById]
	)

	const pushSuccess = useCallback(
		(description: ReactNode, duration?: number) => {
			dispatch('success', {
				description,
				duration,
			})
		},
		[dispatch]
	)

	const pushError = useCallback(
		(description: ReactNode, duration?: number) => {
			dispatch('error', {
				description,
				duration,
			})
		},
		[dispatch]
	)

	const pushInfo = useCallback(
		(description: ReactNode, duration?: number) => {
			dispatch('info', {
				description,
				duration,
			})
		},
		[dispatch]
	)

	useEffect(() => {
		const pauseAll = () => {
			Object.values(timerRef.current ?? {}).forEach((timer) => {
				timer.pause()
			})
		}

		const resumeAll = () => {
			Object.values(timerRef.current ?? {}).forEach((timer) => {
				timer.resume()
			})
		}

		window.addEventListener('blur', pauseAll)
		window.addEventListener('focus', resumeAll)

		return () => {
			window.removeEventListener('blur', pauseAll)
			window.removeEventListener('focus', resumeAll)

			Object.values(timerRef.current ?? {}).forEach((timer) => {
				timer.clearTimeout()
			})
		}
	}, [])

	return {
		toasts,
		dispatch,
		pushSuccess,
		pushError,
		pushInfo,
		handleRemove,
		handleMouseEnter,
		handleMouseLeave,
		handleDragEnd,
	}
})
