const { isLocal, getHostByCountryCode } = require('configs')

const domains = [
	{
		domain: getHostByCountryCode('sportsaustria'),
		defaultLocale: 'de-AT',
		locales: ['de-AT', 'en-AT'],
		http: isLocal,
	},
	{
		domain: getHostByCountryCode('nl'),
		defaultLocale: 'nl-NL',
		locales: ['nl-NL', 'en-NL'],
		http: isLocal,
	},
	{
		domain: getHostByCountryCode('xk'),
		defaultLocale: 'en-XK',
		http: isLocal,
	},
]

/** @type {import('next-i18next').UserConfig} */
module.exports = {
	debug: process.env.DEBUG_I18NEXT === 'true',
	cleanCode: true,
	returnObjects: true,
	saveMissing: true,
	saveMissingPlurals: true,
	keySeparator: false,
	nsSeparator: '|',
	load: 'currentOnly',
	fallbackLng: 'en',
	defaultNS: 'translation',
	ns: ['translation'],
	i18n: {
		localeDetection: false,
		defaultLocale: 'de-AT',
		locales: ['de-AT', 'en-AT', 'nl-NL', 'en-NL', 'en-XK'],
		domains,
	},
}
