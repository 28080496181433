import { ReactElement } from 'react'
import dynamic from 'next/dynamic'

export const DynamicCustomDayPicker = dynamic(
	() => import('./CustomDayPicker'),
	{
		ssr: false,
	}
)

const CustomDayPicker = (props: any): ReactElement => (
	<DynamicCustomDayPicker {...props} />
)

export default CustomDayPicker
