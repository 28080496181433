import { z } from 'zod'

export const DatasourceTheme = z.enum([
	'default',
	'accent-orange',
	'accent-blue',
	'accent-beige',
])

export const StoryContentSchema = {
	component: z.string().trim().min(1),
	_uid: z.string().trim().min(1),
	_editable: z.string().optional(),
}

export const LinkSchema = z.object({
	url: z.string().trim().min(1),
})

export const RichTextRequiredSchema = z.object({
	type: z.literal('doc'),
	content: z.array(z.any()),
})

export const RichTextOptionalSchema = z
	.object({
		type: z.literal('doc'),
		content: z.array(z.any()),
	})
	.optional()

export const ImageRequiredSchema = z.object({
	alt: z.string().or(z.literal('')),
	title: z.string().or(z.literal('')),
	name: z.string().or(z.literal('')),
	filename: z.string().trim().min(1),
})

export const ImageOptionalSchema = z
	.object({
		alt: z.string().or(z.literal(null)).optional(),
		title: z.string().or(z.literal(null)).optional(),
		name: z.string().or(z.literal(null)).optional(),
		filename: z.string().or(z.literal(null)).optional(),
	})
	.optional()
