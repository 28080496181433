import {
	DatasourceTheme,
	ImageOptionalSchema,
	RichTextRequiredSchema,
} from 'schema'
import { z } from 'zod'

export const InfoCardSchema = z.object({
	tag: z.string().optional(),
	title: z.string().trim().min(1),
	description: RichTextRequiredSchema,
	button_text: z.string().optional(),
	button_url: z.string().optional(),
	image: ImageOptionalSchema,
	image_right: z.boolean().optional(),
	theme: DatasourceTheme.optional(),
})
