import { ImageHeader, TextHeader } from '../../molecules'
import { ImageHeaderProps } from '../../molecules/ImageHeader/ImageHeader.data'
import { TextHeaderProps } from '../../molecules/TextHeader/TextHeader.data'

type SectionHeaderProps = ImageHeaderProps | TextHeaderProps

export const SectionHeader = (props: SectionHeaderProps) => {
	const { type, title } = props

	const isHeader = type === 'header'

	if (!title && isHeader) {
		return <div className="section" />
	}

	const isImageHeader = 'imageDesktop' in props && !!props.imageDesktop

	return (
		<>
			{isImageHeader ? <ImageHeader {...props} /> : <TextHeader {...props} />}
		</>
	)
}
