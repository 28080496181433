import { ComponentPropsWithoutRef, forwardRef, ReactElement } from 'react'
import clsx from 'clsx'
import { FieldHint } from '../FieldHint'
import { FieldLabel } from '../FieldLabel'
import { AbstractButton } from '../AbstractButton'
import { XMark } from '../Icons'

export interface InputProps
	extends Omit<ComponentPropsWithoutRef<'input'>, 'prefix'> {
	prefix?: string | ReactElement
	suffix?: string | ReactElement
	onClickSuffix?: () => void
	label?: string
	hint?: string
	wrapperClass?: string
	labelClassName?: string
	isInvalid?: boolean
	isCorrect?: boolean
	canClear?: boolean
	onClear?: () => void
}

const preSuffixClass =
	'p_highlight flex absolute top-1/2 -translate-y-1/2 text-gray-40'

export const Input = forwardRef<HTMLInputElement, InputProps>(
	(
		{
			label,
			prefix,
			suffix,
			onClickSuffix,
			hint,
			wrapperClass,
			labelClassName,
			className,
			isInvalid,
			isCorrect,
			onClear,
			canClear,
			type = 'text',
			...props
		},
		ref
	): ReactElement => (
		<div className={clsx(wrapperClass && wrapperClass)}>
			<label>
				{label && <FieldLabel text={label} className={labelClassName} />}
				<div className="relative">
					{prefix && (
						<span className={clsx('left-4 h-6 w-6', preSuffixClass)}>
							{prefix}
						</span>
					)}
					<input
						ref={ref}
						type={type}
						className={clsx(
							'border-gray-05 placeholder:text-gray-55 hover:border-gray-25 focus:border-gray-90 disabled:bg-gray-05 disabled:placeholder:text-gray-25 disabled:hover:border-gray-05 w-full rounded-full border bg-white px-4 py-3 !ring-transparent disabled:cursor-not-allowed',
							prefix && 'pl-12',
							suffix && 'pr-12',
							canClear && 'pr-12',
							suffix && canClear && 'pr-16',
							isInvalid && '!border-status-error text-status-error',
							isCorrect && '!border-status-correct text-status-correct',
							className && className
						)}
						{...props}
					/>
					{canClear && (
						<AbstractButton
							aria-label="Clear"
							onClick={onClear}
							className={clsx(
								'text-primary absolute top-1/2 -translate-y-1/2 translate-x-1/2',
								suffix ? 'right-12' : 'right-6'
							)}
						>
							<XMark />
						</AbstractButton>
					)}
					{suffix && (
						<span
							className={clsx(
								'right-4 h-6 w-6',
								preSuffixClass,
								onClickSuffix ? 'cursor-pointer' : 'pointer-events-none'
							)}
							onClick={() => {
								onClickSuffix && onClickSuffix()
							}}
						>
							{suffix}
						</span>
					)}
				</div>
				{hint && <FieldHint text={hint} isInvalid={isInvalid} />}
			</label>
		</div>
	)
)
