/* eslint-disable @next/next/no-img-element */
// For further usage, refer to:
// https://github.com/claus/storyblok-rich-text-react-renderer

import Link from 'next/link'
import {
	MARK_BOLD,
	MARK_ITALIC,
	MARK_LINK,
	NODE_PARAGRAPH,
	NODE_OL,
	NODE_UL,
	NODE_IMAGE,
	NODE_QUOTE,
	render,
	StoryblokRichtext,
	RenderOptions,
	NODE_HEADING,
} from 'storyblok-rich-text-react-renderer'
import clsx from 'clsx'
import style from './styles.module.css'
import { optimizeSBImage } from 'common'
import camelCase from 'lodash.camelcase'

interface ResolveOptions extends RenderOptions {
	className?: string
	theme?: string
}

const protocolRegex = new RegExp(/^(mailto:|tel:|https:\/\/|http:\/\/)/)

export class RichTextResolverService {
	richText: StoryblokRichtext

	public constructor(richText: StoryblokRichtext) {
		this.richText = richText
	}

	public resolveToString() {
		let result = ''

		if (!this.richText) {
			return ''
		}

		if (typeof this.richText === 'string') {
			return this.richText
		}

		this.richText.content.forEach(({ content }) => {
			content?.forEach(({ text }) => {
				result += text || ''
			})
		})

		return result
	}

	public baseResolve({
		markResolvers,
		nodeResolvers,
		className,
		...rest
	}: ResolveOptions = {}) {
		return render(this.richText, {
			markResolvers: {
				[MARK_BOLD]: (children) => <strong>{children}</strong>,
				[MARK_ITALIC]: (children) => <em>{children}</em>,
				[MARK_LINK]: (children, props) => {
					const { href, target } = props ?? {}

					if (!href) {
						return <></>
					}

					const isInnerLink = !protocolRegex.test(href)

					if (isInnerLink) {
						return (
							<Link href={href} className={style.link}>
								{children}
							</Link>
						)
					}

					return (
						<a href={href} target={target} className={style.link}>
							{children}
						</a>
					)
				},
				...markResolvers,
			},
			nodeResolvers: {
				[NODE_HEADING]: (children, { level }) => {
					switch (level) {
						case 1: {
							return (
								<h1 className="mb-2 w-full text-5xl font-extrabold leading-[3.5rem]">
									{children}
								</h1>
							)
						}
						case 2: {
							return (
								<h2 className="mb-2 w-full text-[2.5rem] font-extrabold normal-case leading-[3rem]">
									{children}
								</h2>
							)
						}
						case 3: {
							return (
								<h3 className="mb-2 w-full text-[2rem] font-extrabold leading-[2.5rem]">
									{children}
								</h3>
							)
						}
						case 4: {
							return (
								<h4 className="mb-2 w-full text-2xl font-extrabold">
									{children}
								</h4>
							)
						}
						case 5: {
							return (
								<h5 className="mb-2 w-full text-base font-extrabold">
									{children}
								</h5>
							)
						}
						case 6: {
							return (
								<h6 className="mb-2 w-full text-xs font-extrabold">
									{children}
								</h6>
							)
						}
					}
				},
				[NODE_PARAGRAPH]: (children: any) => {
					if (!children) {
						return null
					}

					return (
						<p className={clsx('word_break w-full', className)}>{children}</p>
					)
				},
				[NODE_OL]: (children) => (
					<ol className={clsx(style.list, style.orderedListItem)}>
						{children}
					</ol>
				),
				[NODE_UL]: (children) => (
					<ul className={clsx(style.list, style.unorderedListItem)}>
						{children}
					</ul>
				),
				[NODE_IMAGE]: (_children, { src, alt, ...props }) => (
					<img
						alt={alt as string}
						src={optimizeSBImage(src as string)}
						decoding="async"
						loading="lazy"
						className="my-8 object-contain"
						{...props}
					/>
				),
				[NODE_QUOTE]: (children) => {
					return (
						<blockquote className="[&_p]:title title my-8 before:content-['“'] after:content-['”'] [&_p]:inline [&_p]:w-auto">
							{children}
						</blockquote>
					)
				},
				...nodeResolvers,
			},
			defaultBlokResolver: (name, props) => (
				<div>
					<code>Missing blok resolver for blok type &quot;{name}&quot;.</code>
					<pre>
						<code>{JSON.stringify(props, undefined, 2)}</code>
					</pre>
				</div>
			),
			...rest,
		})
	}

	public resolveToCamelCase() {
		return camelCase(this.resolveToString())
	}
}
