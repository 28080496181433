// For further usage, refer to:
// https://github.com/claus/storyblok-rich-text-react-renderer

import {
	MARK_LINK,
	NODE_PARAGRAPH,
	render,
	StoryblokRichtext,
} from 'storyblok-rich-text-react-renderer'
import { RichTextResolverService as BaseService } from 'service'
import Link from 'next/link'

const protocolRegex = new RegExp(/^(mailto:|tel:|https:\/\/|http:\/\/)/)

export default class RichTextResolverService extends BaseService {
	public constructor(richText: StoryblokRichtext) {
		super(richText)
	}

	public resolveFormItemLabel() {
		return render(this.richText, {
			nodeResolvers: {
				[NODE_PARAGRAPH]: (children) => <strong>{children}</strong>,
			},
			markResolvers: {
				[MARK_LINK]: (children, props) => {
					const { href } = props ?? {}

					if (!href) {
						return <></>
					}

					const isInnerLink = !protocolRegex.test(href)

					if (isInnerLink) {
						return (
							<Link
								href={href}
								className="font-extrabold underline decoration-solid"
							>
								{children}
							</Link>
						)
					}

					return (
						<a
							href={href}
							target="_blank"
							className="font-extrabold underline decoration-solid"
						>
							{children}
						</a>
					)
				},
			},
		})
	}

	public resolveRichText(className?: string) {
		return this.baseResolve({
			className,
		})
	}
}
