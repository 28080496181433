import clsx from 'clsx'
import { ComponentPropsWithoutRef } from 'react'
import { AbstractButton, ArrowLeft, ArrowRight } from 'ui'

const arrowClassList =
	'flex justify-center items-center h-12 w-12 rounded-full transition-colors active:bg-gray-70 disabled:bg-gray-10 disabled:cursor-not-allowed bg-white text-black hover:bg-gray-25 disabled:text-gray-25'

interface ArrowsProps extends ComponentPropsWithoutRef<'div'> {
	onPrevious: () => void
	onNext: () => void
}

export function Arrows({
	onNext,
	onPrevious,
	className,
	...props
}: ArrowsProps) {
	return (
		<div className={clsx('flex gap-4', className)} {...props}>
			<AbstractButton
				aria-label="Previous"
				onClick={onPrevious}
				className={arrowClassList}
			>
				<ArrowLeft />
			</AbstractButton>
			<AbstractButton
				aria-label="Next"
				onClick={onNext}
				className={arrowClassList}
			>
				<ArrowRight />
			</AbstractButton>
		</div>
	)
}
