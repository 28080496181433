import { useEffect } from 'react'
import { LoadableComponent } from 'next/dynamic'

export const useLazyPreload = (
	component: LoadableComponent,
	delay: number | false = 5000
) => {
	useEffect(() => {
		if (!delay) {
			// @ts-ignore
			component.render?.preload?.()
			return
		}

		const timeout = setTimeout(() => {
			// @ts-ignore
			component.render?.preload?.()
		}, delay)

		return () => {
			if (timeout) {
				clearTimeout(timeout)
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])
}
