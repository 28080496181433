import { ComponentPropsWithoutRef } from 'react'
import { motion } from 'framer-motion'
import clsx from 'clsx'
import { ProviderProps, SliderStoreProvider, useSliderStore } from './useSlider'
import { Noop, Arrows as BaseArrows, Dots as BaseDots } from 'ui'

const defaultMarginGapClasses = 'lg:-mx-3 md:-mx-3 sm:-mx-4'
const defaultPaddingGapClasses = 'lg:px-3 md:px-3 sm:px-4'

export function Slider({ children, ...props }: ProviderProps) {
	return <SliderStoreProvider {...props}>{children}</SliderStoreProvider>
}

const shadowClassName =
	'z-default pointer-events-none absolute top-0 h-full from-gray-05'

interface TrackProps extends ComponentPropsWithoutRef<'div'> {
	withShadows?: boolean
}

function Track({ children, withShadows = true }: TrackProps) {
	const { controls, getPercent, config, handleDragEnd, count } =
		useSliderStore()

	const { gap, perView } = config

	const x = getPercent()

	return (
		<div
			className={clsx(
				'relative -mt-4 pb-6 pt-4',
				!config.isFullWidth && 'overflow-hidden',
				!gap && defaultMarginGapClasses
			)}
			{...(gap && {
				style: {
					marginLeft: `-${gap}`,
					marginRight: `-${gap}`,
				},
			})}
		>
			{withShadows && (
				<>
					<span
						className={clsx(
							'-left-[1px] bg-gradient-to-r sm:hidden',
							shadowClassName,
							!gap && 'w-4'
						)}
						{...(gap && {
							style: {
								width: gap,
							},
						})}
					/>
					<span
						className={clsx(
							'-right-[1px] bg-gradient-to-l sm:hidden',
							shadowClassName,
							!gap && 'w-4'
						)}
						{...(gap && {
							style: {
								width: gap,
							},
						})}
					/>
				</>
			)}
			<motion.div
				className={clsx('flex w-full', count <= perView && 'justify-center')}
				animate={controls}
				drag={perView >= count ? false : 'x'}
				dragMomentum={false}
				onDragEnd={handleDragEnd}
				initial={{
					x,
				}}
				transition={{
					type: 'spring',
					damping: 30,
					stiffness: 400,
				}}
			>
				{children}
			</motion.div>
		</div>
	)
}

function Slide({ children, className }: ComponentPropsWithoutRef<'div'>) {
	const { config } = useSliderStore()

	const { gap, perView } = config

	return (
		<div
			className={clsx(
				'shrink-0 grow-0',
				!gap && defaultPaddingGapClasses,
				className
			)}
			style={{
				flexBasis: `${100 / perView}%`,
				...(gap && {
					paddingLeft: `${gap}`,
					paddingRight: `${gap}`,
				}),
			}}
		>
			{children}
		</div>
	)
}

function Arrows() {
	const { handleNext, handlePrevious, count, config } = useSliderStore()

	if (count <= config.perView) {
		return <Noop />
	}

	return <BaseArrows onNext={handleNext} onPrevious={handlePrevious} />
}

function Dots(props: Omit<ComponentPropsWithoutRef<'div'>, 'onClick'>) {
	const { count, config, currentSlide, handleGoTo } = useSliderStore()

	if (count <= config.perView) {
		return <Noop />
	}

	return (
		<BaseDots
			count={count - Math.floor(config.perView - 1)}
			active={currentSlide}
			onClick={handleGoTo}
			theme={config.theme}
			{...props}
		/>
	)
}

Slider.Track = Track
Slider.Slide = Slide
Slider.Arrows = Arrows
Slider.Dots = Dots
