import { ComponentProps } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { Radio, RadioProps } from '../Radio'

interface ControlledRadioProps extends RadioProps {
	name: string
	value: Pick<ComponentProps<'input'>, 'value'>['value']
}

export const ControlledRadio = ({
	name,
	onChange: _onChange,
	...props
}: ControlledRadioProps) => {
	const { control } = useFormContext()

	return (
		<Controller
			name={name}
			control={control}
			render={({
				fieldState: { error },
				field: { onChange, value, ...field },
			}) => (
				<Radio
					isInvalid={!!error}
					checked={props.value === value}
					onChange={(event) => {
						onChange(props.value)

						if (_onChange) {
							_onChange(event)
						}
					}}
					{...field}
					{...props}
				/>
			)}
		/>
	)
}
