export const optimizeSBImage = (filename: string, width?: number) => {
	if (!filename.includes('a.storyblok.com') || filename.includes('.svg')) {
		return filename
	}

	if (width) {
		return `${filename}/m/fit-in/${width}x0`
	}

	return `${filename}/m/`
}
