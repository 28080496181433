import {
	ComponentPropsWithRef,
	ComponentPropsWithoutRef,
	ForwardedRef,
	ReactNode,
	forwardRef,
} from 'react'
import { createPortal } from 'react-dom'
import { motion, Variants } from 'framer-motion'
import clsx from 'clsx'
import { Button, ButtonProps, ClientOnly } from 'ui'
import { MEDIA, useOutsideClick, useScrollLock } from 'common'
import { Scrollable } from '../Scrollable'
import { Backdrop } from '../Backdrop'
import { TRANSITION } from '../Overlays.data'
import { Title } from '../Title'

const mobileVariants: Variants = {
	show: {
		y: '0%',
		transition: TRANSITION,
	},
	hide: {
		y: '100%',
		transition: TRANSITION,
	},
}

const desktopVariants: Variants = {
	show: {
		y: 0,
		opacity: 1,
		transition: TRANSITION,
	},
	hide: {
		y: 20,
		opacity: 0,
		transition: TRANSITION,
	},
}

interface Modal extends ComponentPropsWithoutRef<'div'> {
	title?: string
	isPersistent?: boolean
	onClose: () => void
}

function Modal({ title, onClose, isPersistent, children, className }: Modal) {
	const modalRef = useOutsideClick<HTMLDivElement>(onClose, isPersistent)

	// This is safe because Modal is wrapped with ClientOnly, so it renders only in browser
	const isMobile = window.matchMedia(MEDIA.mobile).matches

	useScrollLock(true)

	const content = (
		<Backdrop isOpened>
			<motion.div
				ref={modalRef}
				variants={isMobile ? mobileVariants : desktopVariants}
				animate="show"
				initial="hide"
				exit="hide"
				className={clsx(
					'relative flex w-full flex-col bg-white sm:mt-auto sm:max-h-[calc(100%_-_116px)] sm:rounded-t-3xl md:m-auto md:max-h-full md:max-w-[800px] md:rounded-sm lg:m-auto lg:max-h-full lg:max-w-[800px] lg:rounded-sm',
					className
				)}
			>
				<Title title={title} onClose={onClose} />
				{children}
			</motion.div>
		</Backdrop>
	)

	return createPortal(content, document.getElementById('modal') as HTMLElement)
}

interface Body extends ComponentPropsWithRef<'div'> {
	children: ReactNode
}

function Body(
	{ children, className, ...props }: Body,
	ref: ForwardedRef<HTMLDivElement>
) {
	return (
		<Scrollable ref={ref} className={clsx(className)} {...props}>
			{children}
		</Scrollable>
	)
}

interface Bottom extends ComponentPropsWithoutRef<'div'> {
	children: ReactNode
}

function Bottom({ children, className, ...props }: Bottom) {
	return (
		<div className="sm:px-6 md:px-14 lg:px-14" {...props}>
			<div
				className={clsx(
					'border-gray-10 flex gap-2 border-t sm:py-2 md:justify-end md:py-6 lg:justify-end lg:py-6',
					className
				)}
			>
				{children}
			</div>
		</div>
	)
}

interface PrimaryButton extends ButtonProps {
	children: ReactNode
}

function PrimaryButton({ children, className, ...props }: ButtonProps) {
	return (
		<Button
			theme="colored"
			className={clsx(
				'sm:min-w-[120px] sm:shrink-[10000] sm:grow sm:truncate sm:[&_span]:truncate',
				className
			)}
			{...props}
		>
			{children}
		</Button>
	)
}

interface SecondaryButton extends ButtonProps {
	children: ReactNode
}

function SecondaryButton({ children, className, ...props }: ButtonProps) {
	return (
		<Button
			className={clsx('ml-auto sm:truncate sm:[&_span]:truncate', className)}
			variant="secondary"
			{...props}
		>
			{children}
		</Button>
	)
}

WithClientOnly.Body = forwardRef(Body)
WithClientOnly.Bottom = Bottom
WithClientOnly.PrimaryButton = PrimaryButton
WithClientOnly.SecondaryButton = SecondaryButton

export default function WithClientOnly(props: Modal) {
	return (
		<ClientOnly>
			<Modal {...props} />
		</ClientOnly>
	)
}
