export const InfoTooltip = () => {
	return (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				id="Subtract"
				fillRule="evenodd"
				clipRule="evenodd"
				d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM10.7642 13.824C10.6842 14.176 10.6442 14.528 10.6442 14.88H12.9482C12.9482 14.5813 12.9642 14.3547 12.9962 14.2C13.0282 14.0453 13.1189 13.8453 13.2682 13.6C13.3482 13.4773 13.4389 13.36 13.5402 13.248C13.6469 13.1307 13.7642 13.008 13.8922 12.88C14.0255 12.7467 14.1749 12.592 14.3402 12.416C14.7242 12.0053 15.0469 11.5627 15.3082 11.088C15.5695 10.6133 15.7002 10.0853 15.7002 9.504C15.7002 9.14133 15.6362 8.78133 15.5082 8.424C15.3802 8.06133 15.1989 7.744 14.9642 7.472C14.6122 7.04 14.1669 6.73067 13.6282 6.544C13.0895 6.352 12.5162 6.256 11.9082 6.256C11.5402 6.256 11.1722 6.30933 10.8042 6.416C10.4362 6.52267 10.1002 6.67733 9.79619 6.88C9.38019 7.152 9.04152 7.52533 8.78019 8C8.51885 8.47467 8.35619 8.99733 8.29219 9.568H10.5962C10.5909 9.36 10.6229 9.17067 10.6922 9C10.7669 8.82933 10.8789 8.688 11.0282 8.576C11.1509 8.48 11.2975 8.41067 11.4682 8.368C11.6442 8.32533 11.8229 8.304 12.0042 8.304C12.2442 8.304 12.4682 8.344 12.6762 8.424C12.8895 8.49867 13.0602 8.624 13.1882 8.8C13.2682 8.90133 13.3215 9.01867 13.3482 9.152C13.3802 9.28533 13.3962 9.424 13.3962 9.568C13.3962 9.84533 13.3589 10.1013 13.2842 10.336C13.2149 10.5653 13.0709 10.8 12.8522 11.04C12.5695 11.3387 12.3242 11.5707 12.1162 11.736C11.9135 11.9013 11.7322 12.064 11.5722 12.224C11.4175 12.3787 11.2735 12.592 11.1402 12.864C10.9749 13.152 10.8495 13.472 10.7642 13.824ZM10.6602 15.696V18H12.9482V15.696H10.6602Z"
				fill="#D0D0D0"
			/>
		</svg>
	)
}
