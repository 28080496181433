import { z } from 'zod'
import { StoryblokImageGallerySchema } from './StoryblokImageGallery.schema'
import { StoryblokRichtext } from 'storyblok-rich-text-react-renderer'
import { Noop } from 'ui'
import RichTextResolverService from 'service/RichTextResolverService'
import ImageGallery from '../ImageGallery'

const renderDescription = (text?: StoryblokRichtext) => {
	if (!text) {
		return <Noop />
	}

	return new RichTextResolverService(text).resolveRichText()
}

type StoryblokImageGalleryProps = {
	blok: z.TypeOf<typeof StoryblokImageGallerySchema>
	parent?: string
}

export const StoryblokImageGallery = ({
	blok,
	parent,
}: StoryblokImageGalleryProps) => {
	const {
		title,
		subtitle,
		tag,
		theme,
		secondary_text,
		image,
		video_id,
		video_title,
		scroll_id,
		image_position,
		hide_show_more_button,
		primary_button_text,
		primary_button_url,
		secondary_button_text,
		secondary_button_url,
	} = blok

	const description = renderDescription(secondary_text)

	return (
		<ImageGallery
			title={title}
			subtitle={subtitle}
			tag={tag}
			theme={theme}
			secondaryText={description}
			image={image?.filename}
			videoId={video_id}
			videoTitle={video_title}
			scrollId={scroll_id}
			imagePosition={image_position}
			hideShowMoreButton={hide_show_more_button}
			primaryButtonText={primary_button_text}
			primaryButtonUrl={primary_button_url}
			secondaryButtonText={secondary_button_text}
			secondaryButtonUrl={secondary_button_url}
			parent={parent}
		/>
	)
}
