// If changed, update tailwind.css media queries as well
const breakpoints = new Map([
	['small', 640],
	['medium', 820],
	['large', 1032],
	['xLarge', 1536],
])

module.exports = {
	breakpoints,
}
