import { ComponentPropsWithoutRef, forwardRef, ReactElement } from 'react'
import clsx from 'clsx'
import { FieldHint, FieldLabel } from 'ui'

export interface TextareaProps extends ComponentPropsWithoutRef<'textarea'> {
	label?: string
	hint?: string
	wrapperClass?: string
	isInvalid?: boolean
	isCorrect?: boolean
}
export const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>(
	(
		{ label, hint, className, wrapperClass, isInvalid, isCorrect, ...props },
		ref
	): ReactElement => (
		<div className={clsx(wrapperClass && wrapperClass)}>
			<label>
				{label && <FieldLabel text={label} />}
				<textarea
					ref={ref}
					className={clsx(
						'border-gray-05 w-full rounded-lg border bg-white px-4 !ring-transparent',
						'placeholder:text-gray-55',
						'hover:border-gray-25',
						'focus:border-black',
						'disabled:bg-gray-05 disabled:border-gray-05 disabled:placeholder:text-gray-25 disabled:hover:border-gray-05 disabled:cursor-not-allowed',
						isInvalid && '!border-status-error text-status-error',
						isCorrect && '!border-status-correct text-status-correct',
						className && className
					)}
					rows={3}
					{...props}
				/>
				{hint && <FieldHint text={hint} isInvalid={isInvalid} />}
			</label>
		</div>
	)
)
