import { z } from 'zod'
import { Accordion } from '../Accordion'
import { StoryblokAccordionSchema } from './StoryblokAccordion.schema'
import { RichTextResolverService } from 'service'

type StoryblokAccordionProps = {
	blok: z.TypeOf<typeof StoryblokAccordionSchema>
}

export const StoryblokAccordion = ({ blok }: StoryblokAccordionProps) => {
	return (
		<Accordion title={blok.title}>
			{new RichTextResolverService(blok.content).baseResolve()}
		</Accordion>
	)
}
