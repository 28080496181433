import DynamicComponent from '@components/common/DynamicComponents/DynamicComponent'
import { StoryblokSlider, StoryblokSliderProps } from 'ui'

const StoryblokCarousel = (props: Omit<StoryblokSliderProps, 'children'>) => {
	return (
		<StoryblokSlider {...props}>
			{(item: any) => (
				<DynamicComponent blok={item} className="h-full" parent="carousel" />
			)}
		</StoryblokSlider>
	)
}

export default StoryblokCarousel
