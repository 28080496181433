import { Controller, useFormContext } from 'react-hook-form'
import { Textarea, TextareaProps } from '../Textarea'

interface ControlledTextareaProps extends TextareaProps {
	name: string
}

export const ControlledTextarea = ({
	name,
	defaultValue = '',
	...props
}: ControlledTextareaProps) => {
	const { control } = useFormContext()

	return (
		<Controller
			name={name}
			control={control}
			defaultValue={defaultValue}
			render={({ fieldState: { error }, field }) => (
				<Textarea
					isInvalid={!!error}
					hint={error?.message}
					{...props}
					{...field}
				/>
			)}
		/>
	)
}
