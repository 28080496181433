import { ImageRequiredSchema } from 'schema'
import { z } from 'zod'

export const StoryblokTeamCardSchema = z.object({
	image: ImageRequiredSchema,
	name: z.string().trim().min(1),
	position: z.string().trim().min(1),
	phone: z.string().optional(),
	mobile_phone: z.string().optional(),
	email: z.string().optional(),
})
