import { Controller, useFormContext } from 'react-hook-form'
import { DatePicker, DatePickerProps } from '../DatePicker'

interface ControlledDatePickerProps extends Omit<DatePickerProps, 'onSelect'> {
	name: string
	disabledInput?: boolean
	forceOpened?: boolean
	selected?: Record<string, Date | undefined>
	onSelect?: () => void
}

export const ControlledDatePicker = ({
	name,
	...props
}: ControlledDatePickerProps) => {
	const { control } = useFormContext()

	return (
		<Controller
			name={name}
			control={control}
			render={({
				fieldState: { error },
				field: { onChange, onBlur, value },
			}) => (
				<DatePicker
					isInvalid={!!error}
					errorText={error?.message}
					onSelect={(date) => {
						if (!date) {
							return
						}

						const year = date.getFullYear()
						const month = String(date.getMonth() + 1).padStart(2, '0')
						const day = String(date.getDate()).padStart(2, '0')

						const isoDate = `${year}-${month}-${day}T00:00:00.000Z`

						onChange(isoDate)
					}}
					onDayBlur={onBlur}
					value={value}
					{...props}
				/>
			)}
		/>
	)
}
