export const isLongEnough = (param: string) => {
	return (param && param.length > 7) || false
}

export const hasNumber = (param: string) => {
	return /[0-9]/.test(param) || false
}

export const hasLetter = (param: string) => {
	if (param && param.match(/[a-zA-Z]/g)) return true
	else return false
}
