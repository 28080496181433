import { format as fnsFormat, formatDistance } from 'date-fns'
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz'

export const toCET = (date: Date | string | number = new Date()) =>
	utcToZonedTime(date, 'Europe/Berlin')

export const fromCET = (date: Date | string | number = new Date()) =>
	zonedTimeToUtc(date, 'Europe/Berlin')

export const safeFormat = (date: string | Date, format = 'dd/MM/yyyy') => {
	try {
		return fnsFormat(new Date(date), format)
	} catch {
		return ''
	}
}

export const formatWithDaysAgo = (date: Date | null): string | null => {
	if (!date) return null

	return `${safeFormat(date, 'yyyy.MM.dd')} (${formatDistance(
		date,
		new Date(),
		{
			addSuffix: true,
		}
	)})`
}

export const formatDateInRecord = (data: Record<string, any>) => {
	for (const key in data) {
		const value = data[key]
		if (typeof value === 'string' && value.includes('T00:00:00.000Z')) {
			data[key] = value.replace('T00:00:00.000Z', '')
		}
	}
}
