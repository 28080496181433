import { useFormContext } from 'react-hook-form'
import { Button, type ButtonProps } from 'ui'

type SubmitButtonProps = ButtonProps

export const SubmitButton = ({ children, ...props }: SubmitButtonProps) => {
	const { formState } = useFormContext()

	return (
		<Button
			type="submit"
			isLoading={formState.isSubmitting}
			isDisabled={!formState.isValid}
			{...props}
		>
			{children}
		</Button>
	)
}
