import { z } from 'zod'
import { StoryContentSchema } from 'schema'

export const PageSectionSchema = z
	.object({
		title: z.string().optional(),
		body: z.array(z.any()),
		scroll_id: z.string().optional(),
	})
	.extend(StoryContentSchema)
