import clsx from 'clsx'
import { ComponentPropsWithoutRef } from 'react'
import { useTranslation } from 'next-i18next'
import { sanitize } from '@utils/sanitize'

const Recaptcha = ({ className, ...props }: ComponentPropsWithoutRef<'p'>) => {
	const { t } = useTranslation()

	const content = t(
		"This site is protected by reCAPTCHA and the Google <a class='text-primary font-bold underline' target='_blank' rel='noopener noreferrer' href='https://policies.google.com/privacy'>privacy policy</a> and <a class='text-primary font-bold underline' target='_blank' rel='noopener noreferrer' href='https://policies.google.com/terms'>Terms of Service</a> apply."
	)

	return (
		<div className={clsx('w-full', className)}>
			<p
				className="micro text-gray-70 inline-block"
				dangerouslySetInnerHTML={sanitize(content, { ADD_ATTR: ['target'] })}
				{...props}
			/>
			<div id="recaptcha-checkbox-container" className="mt-4 empty:mt-0" />
		</div>
	)
}

export default Recaptcha
