import { Controller, useFormContext } from 'react-hook-form'
import PhoneInput from 'react-phone-input-2'
import clsx from 'clsx'
import { FieldHint, FieldLabel } from 'ui'
import 'react-phone-input-2/lib/style.css'

interface ControlledPhoneInputProps {
	name: string
	label?: string
	wrapperClass?: string
	countryCode?: string
}

export const ControlledPhoneInput = ({
	name,
	label,
	wrapperClass,
	countryCode,
	...props
}: ControlledPhoneInputProps) => {
	const { control } = useFormContext()

	return (
		<Controller
			name={name}
			control={control}
			render={({
				fieldState: { error },
				field: { name, ref: _ref, ...rest },
			}) => (
				<div className={clsx(wrapperClass && wrapperClass)}>
					<label>
						{label && <FieldLabel text={label} />}
						<PhoneInput
							inputProps={{
								name,
								...props,
							}}
							inputClass={clsx(
								'!text-base !py-3 !h-auto !rounded-full !w-full !ring-transparent placeholder:!text-gray-55',
								error
									? '!border-status-error !text-status-error'
									: '!border-gray-05 hover:!border-gray-25 focus:!border-gray-90'
							)}
							buttonClass="!top-[1px] !left-[1px] !bottom-[1px] !border-0 !rounded-l-full [&>*:nth-child(1)]:!rounded-l-full [&>*:nth-child(1)]:hover:!rounded-l-full"
							dropdownClass="!w-[276px] !rounded-2xl !py-2"
							country={countryCode}
							{...rest}
						/>
						{error?.message && (
							<FieldHint text={error?.message} isInvalid={!!error} />
						)}
					</label>
				</div>
			)}
		/>
	)
}
