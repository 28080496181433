import { ComponentPropsWithoutRef } from 'react'
import clsx from 'clsx'
import { AbstractButton, XMark } from 'ui'

interface Title extends ComponentPropsWithoutRef<'div'> {
	title?: string
	onClose: () => void
}

export function Title({ title, onClose, className, ...props }: Title) {
	return (
		<div
			className={clsx(
				'modal_title_wrapper flex items-center justify-between sm:px-8 sm:pb-4 sm:pt-8 md:px-14 md:pb-3 md:pt-12 lg:px-14 lg:pb-3 lg:pt-12',
				className
			)}
			{...props}
		>
			{title && <h4 className="subtitle_highlight">{title}</h4>}
			<AbstractButton
				onClick={onClose}
				className="border-gray-05 ml-auto shrink-0 rounded-full border-2 p-2.5"
			>
				<XMark />
			</AbstractButton>
		</div>
	)
}
