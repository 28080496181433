import { ComponentProps, ElementType, ReactNode, forwardRef } from 'react'
import clsx from 'clsx'

type ContainerProps = ComponentProps<'div'> & {
	children: ReactNode
	element?: ElementType
	isNested?: boolean
	isNarrow?: boolean
}

export const Container = forwardRef<HTMLDivElement, ContainerProps>(
	(
		{ children, className, element = 'div', isNested, isNarrow, ...props },
		ref
	) => {
		const Component = element

		return (
			<Component
				ref={ref}
				className={clsx(
					'custom-container mx-auto w-full px-6 sm:px-4',
					isNested && 'nested-container',
					isNarrow
						? 'sm:max-w-none md:max-w-[638px] lg:max-w-[728px]'
						: 'max-w-8xl',
					className && className
				)}
				{...props}
			>
				{children}
			</Component>
		)
	}
)
