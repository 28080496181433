import { ComponentPropsWithoutRef } from 'react'

export const ChevronDown = (props: ComponentPropsWithoutRef<'svg'>) => (
	<svg
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M12 16a.996.996 0 0 1-.707-.293l-6-6a1 1 0 1 1 1.414-1.414L12 13.588l5.294-5.294a1 1 0 1 1 1.414 1.414l-6 6A.997.997 0 0 1 12 16Z"
			fill="currentColor"
		/>
	</svg>
)
