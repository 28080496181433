import { Controller, useFormContext } from 'react-hook-form'
import { Select, type SelectProps } from 'ui'

interface ControlledSelectProps extends SelectProps {
	name: string
}

export const ControlledSelect = ({ name, ...props }: ControlledSelectProps) => {
	const { control } = useFormContext()

	return (
		<Controller
			name={name}
			control={control}
			render={({ fieldState: { error }, field }) => (
				<Select
					isInvalid={!!error}
					hint={error?.message}
					{...props}
					{...field}
				/>
			)}
		/>
	)
}
