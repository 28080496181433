import { ReactElement, ReactNode, useEffect, useState } from 'react'
import { Noop } from '../Noop'

interface ClientOnlyProps {
	children: ReactElement
	fallback?: ReactNode
}

export const ClientOnly = ({
	children,
	fallback,
}: ClientOnlyProps): ReactElement => {
	const [isMounted, setIsMounted] = useState(false)

	useEffect(() => {
		setIsMounted(true)
	}, [])

	if (!isMounted && fallback) {
		return <>{fallback}</>
	}

	if (!isMounted) {
		return <Noop />
	}

	return children
}
