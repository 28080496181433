import clsx from 'clsx'

interface YouTubeIframeProps {
	video_id: string
	className?: string
}

const YouTubeIframe = ({ video_id, className }: YouTubeIframeProps) => {
	return (
		<iframe
			className={clsx('w-full', className && className)}
			sandbox="allow-same-origin allow-forms allow-popups allow-scripts allow-presentation"
			src={`https://youtube.com/embed/${video_id}`}
		></iframe>
	)
}

export default YouTubeIframe
