import { ComponentPropsWithoutRef } from 'react'
import clsx from 'clsx'

interface FieldLabelProps extends ComponentPropsWithoutRef<'span'> {
	text: string
}

export const FieldLabel = ({ text, className, ...props }: FieldLabelProps) => (
	<span
		className={clsx('p_highlight mb-2 inline-block', className && className)}
		{...props}
	>
		{text}
	</span>
)
