import { ComponentPropsWithoutRef, forwardRef, ReactElement } from 'react'
import clsx from 'clsx'

export interface RadioProps
	extends Omit<ComponentPropsWithoutRef<'input'>, 'type'> {
	label: string | ReactElement
	isInvalid?: boolean
	wrapperClass?: string
}

export const Radio = forwardRef<HTMLInputElement, RadioProps>(
	({ label, className, wrapperClass, disabled, isInvalid, ...props }, ref) => (
		<div className={clsx(wrapperClass && wrapperClass)}>
			<label
				className={clsx(
					'inline-flex items-center',
					disabled ? 'cursor-not-allowed' : 'cursor-pointer'
				)}
			>
				<input
					ref={ref}
					type="radio"
					disabled={disabled}
					className={clsx(
						'border-primary h-6 w-6 cursor-pointer border-2 bg-transparent',
						'active:border-primary',
						'checked:bg-primary checked:border-primary checked:hover:bg-primary text-primary',
						'focus:shadow-inner-focus focus:ring-transparent focus:checked:shadow-none',
						'disabled:bg-gray-05 disabled:border-gray-05 disabled:checked:bg-disabled-radio disabled:hover:bg-gray-05 disabled:cursor-not-allowed',
						isInvalid
							? '!text-status-error !border-status-error checked:bg-status-error checked:hover:bg-status-error'
							: 'focus:checked:bg-primary focus:checked:border-primary',
						className && className
					)}
					{...props}
				/>
				<span
					className={clsx(
						'p_highlight ml-3',
						disabled && 'text-gray-25',
						isInvalid && 'text-status-error'
					)}
				>
					{label}
				</span>
			</label>
		</div>
	)
)
